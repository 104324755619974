import React,{useState, useEffect} from 'react'
import HomepageModal from '../HomepageModal'

const PopUpOnce = ({ values }) => {
    const [visible, setVisible] = React.useState(false);
    useEffect(()=>{
      let pop_status = localStorage.getItem('pop_status');
      if(!pop_status){
        setVisible(true);
        localStorage.setItem('pop_status',1);
      }
    },[])
    window.onbeforeunload = function()
    {
        localStorage.removeItem('pop_status');
    };
    
    if(!visible) return null;

    return (
        <>
            {values?.map((i, index) => 
                <HomepageModal key={i.id} Iindex={index} iTitle={i.Title} iDocPath={i.DocPath} iLink={i.external_link} iZoom={i.zoom_level} />
            )}    
        </>

    )
}

export default PopUpOnce