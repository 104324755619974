import React, { useEffect, useContext } from 'react'
import { LangContext } from '../../context/LangContext';
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Animation from '../../utils/Animation';

import mobank from '../../assets/img/mobank.webp'
import appstore from '../../assets/img/appstore.png'
import playstore from '../../assets/img/playstore.png'

import './mobileBank.css'

const MobileBank = () => {
  const lang = useContext(LangContext);
  const np = lang.state.np;
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if(inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <motion.div 
    ref={ref}
    animate={controls}
    initial="hidden"
    variants={Animation}
    className='mobileBankSectionContainer'>
        <img className='mobileBankImg' src={mobank} alt="mobank img" />

        <div className='mobileBankDescContainer'>
            <h4 className='mobileBankTitle'><span>{np? 'सजिलो बैंकिङको आनन्द लिनुहोस्' : 'Enjoy easy banking'}</span> {np? 'तपाईको हातमा समातिएका यन्त्रहरूबाट, कुनै पनि समयबाट।' : 'from your hand held devices, from anywhere anytime.'}</h4>
            <p className='mobileBankDesc'>{np? 'प्राइम कमर्शियल बैंकको यो सुरक्षित मोबाइल बैंकिङ एपको साथ चौबीसै घण्टा आफ्नो बैंक खाता व्यवस्थापन र प्रयोग गर्नुहोस्।' : 'Manage and use your Bank Account on the move and around the clock with this secure Mobile Banking App from Prime Commercial Bank.'}</p>

            <div className='mobileBankAppLinkContainer'>
                <a href="https://apps.apple.com/np/app/prime-smart/id1112059608" target="_blank">
                  <img className='mobileBankAppImg' src={appstore} alt="appstore" />
                </a>

                <a href="https://play.google.com/store/search?q=prime%20smart&c=apps&hl=en&gl=US" target="_blank">
                  <img className='mobileBankAppImg' src={playstore} alt="playstore" />
                </a>

            </div>
        </div>
    </motion.div>
  )
}

export default MobileBank