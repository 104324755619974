import React from 'react'
import { Link } from 'react-router-dom'
import notFound from '../../assets/img/svg/404.svg'
import './notFound.css'

const NotFound = () => {
  return (
    <div className='noPageFoundMainContainer'>
      <img className='noPageFoundImage' src={notFound} alt="page-not-found" />
      <h4 className='noPageFoundText'>The requested page was not found. It seems you have ventured onto a broken link</h4>
      <Link to='/' className='noPageFoundBackButton'>
        Back to Home Page
      </Link>
    </div>
  )
}

export default NotFound