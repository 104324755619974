import React, { useContext } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTopUtil from "./utils/ScrollToTopUtil";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { LangContext } from "./context/LangContext";
import ReactGA from "react-ga4";

import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./utils/ErrorFallback";
import { lazy, Suspense } from "react";

// start import styles for lazy loading pages
// import './'
// end import styles for lazy loading pages

import NotFound from "./pages/NotFound";

import ScrollToTop from "react-scroll-to-top";
import { IoIosArrowUp } from "react-icons/io";
import { Toaster } from "react-hot-toast";
import "./App.css";
import Loader from "./utils/Loader";
import NavbarUp from "./component/NavBarUp";
import Footer from "./component/Footer";
import Home from "./pages/Home";
// import CsrReport from "./pages/CsrReport";

const LearningDevelopment = lazy(() => import("./pages/LearningDevelopment"));
const CsrReport = lazy(() => import("./pages/CsrReport"));
const Teams = lazy(() => import("./pages/Teams"));
// import Teams from './pages/Teams';

const News = lazy(() => import("./pages/News"));
// import News from './pages/News';

const InterestRates = lazy(() => import("./pages/InterestRates"));
// import InterestRates from './pages/InterestRates';
const ServiceCharges = lazy(() => import("./pages/ServiceCharges"));
// import ServiceCharges from './pages/ServiceCharges';

const PublicInformation = lazy(() => import("./pages/PubllicInformation"));
// import PublicInformation from './pages/PubllicInformation';

const CSR = lazy(() => import("./pages/CSR"));
// import CSR from './pages/CSR';

const Calculators = lazy(() => import("./pages/Calculators"));
// import Calculators from './pages/Calculators';

const GalleryPage = lazy(() => import("./pages/GalleryPage"));
// import GalleryPage from './pages/GalleryPage';

const ServiceDetails = lazy(() => import("./pages/ServiceDetails"));
// import ServiceDetails from './pages/ServiceDetails';

const Videos = lazy(() => import("./pages/Videos"));
// import Videos from './pages/Videos';

const BlogDescription = lazy(() => import("./pages/BlogDescription"));
// import BlogDescription from './pages/BlogDescription';

const Offers = lazy(() => import("./pages/Offers"));
// import Offers from './pages/Offers';

const OffersDetails = lazy(() => import("./pages/OffersDetails"));
// import OffersDetails from './pages/OffersDetails';

const Branches = lazy(() => import("./pages/Branches"));
// import Branches from './pages/Branches';

const ProvinceHead = lazy(() => import("./pages/ProvinceHead"));
// import ProvinceHead from './pages/ProvinceHead';

const FAQ = lazy(() => import("./pages/FAQ"));
// import FAQ from './pages/FAQ';

const Downloads = lazy(() => import("./pages/Downloads"));
// import Downloads from './pages/Downloads';

const GetInTouch = lazy(() => import("./pages/GetInTouch"));
// import GetInTouch from './pages/GetInTouch';

const AboutIntro = lazy(() => import("./pages/AboutIntro"));
// import AboutIntro from './pages/AboutIntro';

const AboutBod = lazy(() => import("./pages/AboutBod"));
// import AboutBod from './pages/AboutBod';

const AboutAudit = lazy(() => import("./pages/AboutAudit"));
// import AboutAudit from './pages/AboutAudit';

const AboutManagement = lazy(() => import("./pages/AboutManagement"));
// import AboutManagement from './pages/AboutManagement';

const ContactHR = lazy(() => import("./pages/ContactHR"));
// import ContactHR from './pages/ContactHR';
const CurrentVacancy = lazy(() => import("./pages/CurrentVacancy"));
// import CurrentVacancy from './pages/CurrentVacancy';

const GrowWithUs = lazy(() => import("./pages/GrowWithUs"));
// import GrowWithUs from './pages/GrowWithUs';

const ShortListed = lazy(() => import("./pages/ShortListed"));
// import ShortListed from './pages/ShortListed';
const Search = lazy(() => import("./pages/Search"));
// import Search from './pages/Search';
const Calendar = lazy(() => import("./pages/Calendar"));
// import Calendar from './pages/Calendar';
const BlogsPage = lazy(() => import("./pages/BlogsPage"));
// import BlogsPage from './pages/BlogsPage';

const Basel = lazy(() => import("./pages/InvestorRelationSubPages/Basel"));
// import Basel from './pages/InvestorRelationSubPages/Basel';

const QuarterlyReports = lazy(() =>
  import("./pages/InvestorRelationSubPages/QuarterlyReports")
);
// import QuarterlyReports from './pages/InvestorRelationSubPages/QuarterlyReports';
const AnnualReports = lazy(() =>
  import("./pages/InvestorRelationSubPages/AnnualReports")
);
// import AnnualReports from './pages/InvestorRelationSubPages/AnnualReports';
const BaseRateInterestRates = lazy(() =>
  import("./pages/InvestorRelationSubPages/BaseRateInterestRates")
);
// import BaseRateInterestRates from './pages/InvestorRelationSubPages/BaseRateInterestRates';
const AGMMinute = lazy(() =>
  import("./pages/InvestorRelationSubPages/AGMMinute")
);
// import AGMMinute from './pages/InvestorRelationSubPages/AGMMinute';
const CorporateGovernanceReport = lazy(() =>
  import("./pages/InvestorRelationSubPages/CorporateGovernanceReport")
);
// import CorporateGovernanceReport from './pages/InvestorRelationSubPages/CorporateGovernanceReport';
const Annexure14 = lazy(() =>
  import("./pages/InvestorRelationSubPages/Annexure14")
);
// import Annexure14 from './pages/InvestorRelationSubPages/Annexure14';
const Annexure15 = lazy(() =>
  import("./pages/InvestorRelationSubPages/Annexure15")
);
// import Annexure15 from './pages/InvestorRelationSubPages/Annexure15';
const PrincipalFinancialIndicators = lazy(() =>
  import("./pages/InvestorRelationSubPages/PrincipalFinancialIndicators")
);
// import PrincipalFinancialIndicators from './pages/InvestorRelationSubPages/PrincipalFinancialIndicators';
const SubsidizedLoan = lazy(() =>
  import("./pages/InvestorRelationSubPages/SubsidizedLoan")
);
// import SubsidizedLoan from './pages/InvestorRelationSubPages/SubsidizedLoan';
const RefinanceLoan = lazy(() =>
  import("./pages/InvestorRelationSubPages/RefinanceLoan")
);
// import RefinanceLoan from './pages/InvestorRelationSubPages/RefinanceLoan';
const RightToInformation = lazy(() =>
  import("./pages/InvestorRelationSubPages/RightToInformation")
);
// import RightToInformation from './pages/InvestorRelationSubPages/RightToInformation';

const UnclaimedDividendList = lazy(() =>
  import("./pages/InvestorRelationSubPages/UnclaimedDividendList")
);
// import UnclaimedDividendList from './pages/InvestorRelationSubPages/UnclaimedDividendList';

const Credit = lazy(() => import("./pages/TariffsSubPages/Credit"));
// import Credit from './pages/TariffsSubPages/Credit';
const TradeFinance = lazy(() => import("./pages/TariffsSubPages/TradeFinance"));
// import TradeFinance from './pages/TariffsSubPages/TradeFinance';
const Operations = lazy(() => import("./pages/TariffsSubPages/Operations"));
// import Operations from './pages/TariffsSubPages/Operations';
const DigitalProducts = lazy(() =>
  import("./pages/TariffsSubPages/DigitalProducts")
);
// import DigitalProducts from './pages/TariffsSubPages/DigitalProducts';

const ATMs = lazy(() => import("./pages/ATMs"));
// import ATMs from './pages/ATMs';

const CSRDetails = lazy(() => import("./pages/CSRDetails"));
// import CSRDetails from './pages/CSRDetails';
const Forex = lazy(() => import("./pages/Forex"));
// import Forex from './pages/Forex';
const Accounts = lazy(() => import("./pages/ServicesSubPages/Accounts"));
const Dpservices = lazy(() => import("./pages/ServicesSubPages/Dpservices"));
// import Accounts from './pages/ServicesSubPages/Accounts';

const Loans = lazy(() => import("./pages/ServicesSubPages/Loans"));
// import Loans from './pages/ServicesSubPages/Loans';

const Cards = lazy(() => import("./pages/ServicesSubPages/Cards"));
// import Cards from './pages/ServicesSubPages/Cards';

const BankingHours = lazy(() => import("./pages/BankingHours"));
// import BankingHours from './pages/BankingHours';
const GrievanceForm = lazy(() => import("./pages/GrievanceForm"));
// import GrievanceForm from './pages/GrievanceForm';

const GrievanceResForm = lazy(() => import("./pages/GrievanceResForm"));
// import GrievanceResForm from './pages/GrievanceResForm';

const PublicInfoDetails = lazy(() => import("./pages/PublicInfoDetails"));
// import PublicInfoDetails from './pages/PublicInfoDetails';

const InterestDetails = lazy(() => import("./pages/InterestDetails"));
// import InterestDetails from './pages/InterestDetails';

const DownloadsDetails = lazy(() => import("./pages/DownloadsDetails"));
// import DownloadsDetails from './pages/DownloadsDetails';

const NTBill = lazy(() => import("./pages/NTBill"));
// import NTBill from './pages/NTBill';

const NEABill = lazy(() => import("./pages/NEABill"));
// import NEABill from './pages/NEABill';

const VisaMember = lazy(() => import("./pages/VisaMember"));
// import VisaMember from './pages/VisaMember';

const RemitService = lazy(() => import("./pages/RemitService"));
// import RemitService from './pages/RemitService';

const NewsAll = lazy(() => import("./pages/NewsAll"));
// import NewsAll from './pages/NewsAll';

const GetBranchLocation = lazy(() =>
  import("./pages/SelectBranchTwo/GetBranchLocation")
);
// import GetBranchLocation from './pages/SelectBranchTwo/GetBranchLocation';

// const SelectBranch = lazy(() => import('./pages/SelectBranch'));
// import SelectBranch from './pages/SelectBranch';

const SelectBorA = lazy(() => import("./pages/SelectBorA"));
// import SelectBorA from './pages/SelectBorA';

const AboutTeam = lazy(() => import("./pages/AboutTeam"));
const KeyContactsss = lazy(() => import("./pages/keycontacts"));
// import AboutTeam from './pages/AboutTeam';
// const SelectATM = lazy(() => import('./pages/SelectATM'));
// import SelectATM from './pages/SelectATM';
const SafeLocker = lazy(() => import("./pages/ServicesSubPages/SafeLocker"));
// import SafeLocker from './pages/ServicesSubPages/SafeLocker';
const OnlineServices = lazy(() =>
  import("./pages/ServicesSubPages/OnlineServices")
);
// import OnlineServices from './pages/ServicesSubPages/OnlineServices';
const SelectBranchTwo = lazy(() => import("./pages/SelectBranchTwo"));
// import SelectBranchTwo from './pages/SelectBranchTwo';

const SelectATMTwo = lazy(() => import("./pages/SelectATMTwo"));
// import SelectATMTwo from './pages/SelectATMTwo';
const GetATMLocation = lazy(() =>
  import("./pages/SelectATMTwo/GetATMLocation")
);
// import GetATMLocation from './pages/SelectATMTwo/GetATMLocation';
const CorrespondentBanks = lazy(() => import("./pages/CorrespondentBanks"));
// import CorrespondentBanks from './pages/CorrespondentBanks';
const BankGuarantee = lazy(() => import("./pages/BankGuarantee"));
// import BankGuarantee from './pages/BankGuarantee';
const RemitCRN = lazy(() => import("./pages/RemitCRN"));
const DocUpload = lazy(() => import("./pages/RemitCRN/docupload"));
const theme = createTheme({
  palette: {
    third: {
      main: "#fff",
    },
    primary: {
      main: "#a93a7c",
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
});

const TRACKING_ID = "G-B2YNKGL82Y";
ReactGA.initialize(TRACKING_ID);

function App() {
  const lang = useContext(LangContext);
  const np = lang.state.np;

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        {/* <Navbar /> */}
        <NavbarUp />
        <Toaster />
        <ScrollToTopUtil>
          <Routes>
            <Route path="/" element={<Home />} />

            <Route
              path="/guarantee-verification"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <BankGuarantee />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/select-atm-or-branch"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <SelectBorA />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            {/* <Route path="/select-branch" 
            element={
              <ErrorBoundary
              FallbackComponent={ErrorFallback}
            >
              <Suspense fallback={<Loader />}>
                <SelectBranch />
              </Suspense>
            </ErrorBoundary>
              } 
            />   */}
            {/* <Route path="/select-atm" 
              element={
                <ErrorBoundary
                FallbackComponent={ErrorFallback}
              >
                <Suspense fallback={<Loader />}>
                  <SelectATM />
                </Suspense>
              </ErrorBoundary>
              }
            /> */}
            <Route
              path="/select-branch"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <SelectBranchTwo />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/get-branch-location"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <GetBranchLocation />
                  </Suspense>
                </ErrorBoundary>
              }
            />

            <Route
              path="/select-atm"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <SelectATMTwo />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/get-atm-location"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <GetATMLocation />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            {/* <Route path="/branch-location" element={<GetBranchLocation />} /> */}

            <Route
              path="/teams"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <Teams />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/news-and-notices/:id"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <News />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/news-and-notices"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <NewsAll />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/interest-details/:id"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <InterestDetails />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/interest-rates"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <InterestRates />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/service-charges"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <ServiceCharges />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/public-information"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <PublicInformation />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/public-information-details/:slug"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <PublicInfoDetails />
                  </Suspense>
                </ErrorBoundary>
              }
            />

            <Route
              path="/csr"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <CSR />
                  </Suspense>
                </ErrorBoundary>
              }
            />

            <Route
              path="/csr-details/:slug"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <CSRDetails />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/calculators"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <Calculators />
                  </Suspense>
                </ErrorBoundary>
              }
            />

            <Route
              path="/gallery"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <GalleryPage />
                  </Suspense>
                </ErrorBoundary>
              }
            />

            <Route
              path="/service-details/:slug"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <ServiceDetails />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/videos"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <Videos />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/blog-description/:slug"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <BlogDescription />
                  </Suspense>
                </ErrorBoundary>
              }
            />

            <Route
              path="/offers"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <Offers />
                  </Suspense>
                </ErrorBoundary>
              }
            />

            <Route
              path="/offers-details/:slug"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <OffersDetails />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/branches"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <Branches />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/province-head"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <ProvinceHead />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/atms"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <ATMs />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/faq"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <FAQ />
                  </Suspense>
                </ErrorBoundary>
              }
            />

            <Route
              path="/downloads"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <Downloads />
                  </Suspense>
                </ErrorBoundary>
              }
            />

            <Route
              path="/downloads-details/:id"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <DownloadsDetails />
                  </Suspense>
                </ErrorBoundary>
              }
            />

            <Route
              path="/get-in-touch"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <GetInTouch />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            {/* <Route path="/services" element={<Services />} /> */}

            <Route
              path="/about-intro"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <AboutIntro />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/team-members/:id"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <AboutTeam />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/about-bod"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <AboutBod />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/about-audit"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <AboutAudit />
                  </Suspense>
                </ErrorBoundary>
              }
            />

            <Route
              path="/about-management"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <AboutManagement />
                  </Suspense>
                </ErrorBoundary>
              }
            />

            <Route
              path="/calendar"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <Calendar />
                  </Suspense>
                </ErrorBoundary>
              }
            />

            <Route
              path="/tariffs/credit"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <Credit />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/tariffs/trade-finance"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <TradeFinance />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/tariffs/operations"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <Operations />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/tariffs/digital-products"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <DigitalProducts />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/current-vacancy"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <CurrentVacancy />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/grow-with-us"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <GrowWithUs />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/short-listed"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <ShortListed />
                  </Suspense>
                </ErrorBoundary>
              }
            />

            <Route
              path="/all-blogs"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <BlogsPage />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/investor-relation/basel"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <Basel />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/investor-relation/quarterly-reports"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <QuarterlyReports />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/investor-relation/annual-reports"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <AnnualReports />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/investor-relation/base-rate-and-interest-rates"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <BaseRateInterestRates />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/investor-relation/agm-minute"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <AGMMinute />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/investor-relation/corporate-governance-report"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <CorporateGovernanceReport />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/investor-relation/annexure-14"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <Annexure14 />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/investor-relation/annexure-15"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <Annexure15 />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/investor-relation/principal-financial-indicators"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <PrincipalFinancialIndicators />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/investor-relation/subsidized-loan"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <SubsidizedLoan />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/investor-relation/refinance-loan"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <RefinanceLoan />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/investor-relation/right-to-information"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <RightToInformation />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/investor-relation/unclaimed-dividend-list"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <UnclaimedDividendList />
                  </Suspense>
                </ErrorBoundary>
              }
            />

            <Route
              path="/service/accounts"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <Accounts />
                  </Suspense>
                </ErrorBoundary>
              }
            />

            <Route
              path="/service/dp-services"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <Dpservices />
                  </Suspense>
                </ErrorBoundary>
              }
            />

            <Route
              path="/service/loans"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <Loans />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/service/cards"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <Cards />
                  </Suspense>
                </ErrorBoundary>
              }
            />

            <Route
              path="/service/safe-deposit-locker"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <SafeLocker />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/service/online-services"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <OnlineServices />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/forex"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <Forex />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/banking-hours"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <BankingHours />
                  </Suspense>
                </ErrorBoundary>
              }
            />

            <Route
              path="/grievance-form"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <GrievanceForm />
                  </Suspense>
                </ErrorBoundary>
              }
            />

            <Route
              path="/grievance-response"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <GrievanceResForm />
                  </Suspense>
                </ErrorBoundary>
              }
            />

            <Route
              path="/search"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <Search />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/nt-bill-payment"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <NTBill />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/nea-bill-payment"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <NEABill />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/correspondent-banks"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <CorrespondentBanks />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/visa-member-banks-list"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <VisaMember />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/remittance-services"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <RemitService />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/career/grow-with-us"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <GrowWithUs />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/career/contact-hr"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <ContactHR />
                  </Suspense>
                </ErrorBoundary>
              }
            />

            <Route
              path="/learning-and-development"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <LearningDevelopment />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/key-contact"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <KeyContactsss />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/remit_crn"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <RemitCRN />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/remit_crn_doc_upload"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <DocUpload />
                  </Suspense>
                </ErrorBoundary>
              }
            />

            <Route path="*" element={<NotFound />} />
            <Route
              path="/csr-report"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loader />}>
                    <CsrReport />
                  </Suspense>
                </ErrorBoundary>
              }
            />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </ScrollToTopUtil>
        <Footer />
        <ScrollToTop
          smooth
          component={<IoIosArrowUp color="#353935" fontSize={26} />}
        />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
