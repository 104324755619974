import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { BsArrowRight, BsDownload } from "react-icons/bs";
import FileSaver from "file-saver";
import Box from "@mui/material/Box";
import "react-lazy-load-image-component/src/effects/blur.css";

import ImageZoom from "react-image-zooom";

import "./homepageModal.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, color: "#0f2137" }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const HomepageModal = ({ key, Iindex, iTitle, iDocPath, iLink, iZoom }) => {
  const [open, setOpen] = React.useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // const download = e => {

  //     fetch(e)
  //     .then(response => {
  //       response.arrayBuffer().then(function(buffer) {
  //         const url = window.URL.createObjectURL(new Blob([buffer]));
  //         const link = document.createElement("a");
  //         link.href = url;
  //         link.setAttribute("download", "PCBL-download.jpg");
  //         document.body.appendChild(link);
  //         link.click();
  //       });
  //     })
  //     .catch(err => {
  //       console.log(err);
  //     });
  // };

  // const download = e => {
  //   console.log("e =>", e);
  //   fetch(e, {
  //     method: "GET",
  //     headers: {}
  //   })
  //     .then(response => {
  //       response.arrayBuffer().then(function(buffer) {
  //         const url = window.URL.createObjectURL(new Blob([buffer]));
  //         const link = document.createElement("a");
  //         link.href = url;
  //         link.setAttribute("download", "pcbl-download.jpg"); //or any other extension
  //         document.body.appendChild(link);
  //         link.click();
  //       });
  //     })
  //     .catch(err => {
  //       console.log(err);
  //     });
  // };

  // async function downloadImage(imageSrc) {
  //   const image = await fetch(imageSrc)
  //   const imageBlog = await image.blob()
  //   const imageURL = URL.createObjectURL(imageBlog)

  //   const link = document.createElement('a')
  //   link.href = imageURL
  //   link.download = 'image.jpg'
  //   document.body.appendChild(link)
  //   link.click()
  //   document.body.removeChild(link)
  // }

  return (
    <>
      <BootstrapDialog
        style={{ zIndex: `${999 - Iindex}` }}
        onClose={handleClose}
        scroll="paper"
        TransitionComponent={Transition}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        {/* <BootstrapDialogTitle id="customized-dialog-title" className="homepageModalTitleContainer">
          {iTitle}

          <a className='HomepageModalCloseButtonContainer' onClick={handleClose}>
            <p>Skip</p>
          </a>
        </BootstrapDialogTitle> */}

        <DialogContent id="HomepageModalCustomScroll" dividers="paper">
          <Box>
            <div
              style={{
                position: "absolute",
                top: "6px",
                right: "8px",
                zIndex: "999",
              }}
            >
              <a
                className="HomepageModalCloseButtonContainer"
                onClick={handleClose}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "0 3px",
                }}
              >
                <p>Skip</p>
                <BsArrowRight
                  style={{
                    color: "#fff",
                    fontSize: "16px",
                    strokeWidth: "0.5px",
                  }}
                />
              </a>
            </div>

            <a
              className="HomepageModalKnowButtonContainer"
              style={{
                position: "absolute",
                bottom: "6px",
                right: "8px",
                zIndex: "999",
              }}
              onClick={() => {
                FileSaver.saveAs(iDocPath, "pcbl-download.jpg");
              }}
              // href={iDocPath}
              // download
              // target="_blank"
              // download
              // onClick={download(iDocPath)}
              // onClick={() => download(`https://cors-anywhere.herokuapp.com/${iDocPath}`) }
            >
              <BsDownload
                style={{
                  color: "#fff",
                  fontSize: "18px",
                  strokeWidth: "0.5px",
                }}
              />
            </a>

            {iZoom === "100" ? (
              <img className="HomePageModalImage" alt={key} src={iDocPath} />
            ) : (
              // <img className='HomePageModalImage' src={iDocPath} alt='modal' />
              <ImageZoom
                className="HomePageModalImage"
                src={iDocPath}
                alt={key}
                zoom={iZoom}
              />
            )}
          </Box>
        </DialogContent>
        {/* <DialogActions>

          {iLink.length === 0 ? (
          <a 
          href={iDocPath}
          target="_blank" className='HomepageModalKnowButtonContainer'>
            <p>Know More</p>
          </a>
          ) : (
          <a href={iLink} className='HomepageModalKnowButtonContainer'>
            <p>Know More</p>
          </a>
          )}

          <a
          className='HomepageModalKnowButtonContainer'
          href="#"
          onClick={() => download(`https://cors-anywhere.herokuapp.com/${iDocPath}`) }>
            <p>Download</p>
          </a>
        </DialogActions> */}
      </BootstrapDialog>
    </>
  );
};

export default HomepageModal;
