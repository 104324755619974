import React, { useEffect, useContext } from "react";
import { LangContext } from "../../context/LangContext";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Animation from "../../utils/Animation";
import { Link } from "react-router-dom";

import "./tabs.css";

import calculator from "../../assets/img/calculator.png";
import csr from "../../assets/img/csr.png";
import gallery from "../../assets/img/gallery.png";
import cbanking from "../../assets/img/cbanking.png";
import interestRate from "../../assets/img/interest-rate.png";
import publicInformation from "../../assets/img/public-information.png";
import serviceCharge from "../../assets/img/service-charge.png";
import forex from "../../assets/img/forex.png";
import news from "../../assets/img/news-2.png";

const Tabs = () => {
  const lang = useContext(LangContext);
  const np = lang.state.np;
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <div
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={Animation}
      className="FeaturesTabscontainer"
    >
      <Link to="/service-charges" className="FeaturesTabsbox">
        <img
          className="FeaturesTabsimage"
          src={serviceCharge}
          alt="serviceCharge"
        />
        <p className="FeaturesTabstext">
          {np ? "सेवा शुल्क" : "Service Charges"}
        </p>
      </Link>

      <Link to="/public-information" className="FeaturesTabsbox">
        <img
          className="FeaturesTabsimage"
          src={publicInformation}
          alt="publicInformation"
        />
        <p className="FeaturesTabstext">
          {np ? "सार्वजनिक जानकारी" : "Public Information"}
        </p>
      </Link>

      <Link to="/csr" className="FeaturesTabsbox">
        <img className="FeaturesTabsimage" src={csr} alt="csr" />
        <p className="FeaturesTabstext">{np ? "सी एस आर" : "CSR"}</p>
      </Link>

      <Link to="/interest-rates" className="FeaturesTabsbox">
        <img
          className="FeaturesTabsimage"
          src={interestRate}
          alt="interestRate"
        />
        <p className="FeaturesTabstext">
          {np ? "ब्याज दरहरू" : "Interest Rates"}
        </p>
      </Link>

      <Link to="/news-and-notices" className="FeaturesTabsbox">
        <img className="FeaturesTabsimage" src={news} alt="news" />
        <p className="FeaturesTabstext">{np ? "समाचार" : "News"}</p>
      </Link>

      <Link to="/calculators" className="FeaturesTabsbox">
        <img className="FeaturesTabsimage" src={calculator} alt="calculator" />
        <p className="FeaturesTabstext">
          {np ? "क्याल्कुलेटर" : "Calculators"}
        </p>
      </Link>

      <Link to="/correspondent-banks" className="FeaturesTabsbox">
        <img className="FeaturesTabsimage" src={cbanking} alt="cbanking" />
        <p className="FeaturesTabstext">
          {np ? "संवाददाता बैंकहरू" : "Correspondent Banks"}
        </p>
      </Link>

      <Link to="/forex" className="FeaturesTabsbox">
        <img className="FeaturesTabsimage" src={forex} alt="gallery" />
        <p className="FeaturesTabstext">{np ? "विदेशी मुद्रा" : "Forex"}</p>
      </Link>
    </div>
  );
};

export default Tabs;
