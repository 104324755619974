import React, { createContext, useReducer } from 'react'

export const LangContext = createContext();

const initialState = {
    np: false,
}

const LangReducer = (state, action) => {
    switch (action.type) {
        case "ENGLISH":
            return { np: false };
        case "NEPALI":
            return { np: true };
        default:
            return state;
    }
};

export function LangProvider(props) {
    const [state, dispatch] = useReducer(LangReducer, initialState);

    return <LangContext.Provider value={{ state, dispatch }}>{props.children}</LangContext.Provider>
}