import React, { useState, useEffect, useContext } from 'react'
import { LangContext } from '../../context/LangContext';
import { Link } from 'react-router-dom'
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import ModalVideo from 'react-modal-video' 
import Animation from '../../utils/Animation';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import playButton from '../../assets/img/playButton.png'

import './videos.css';  
import './modal-video.css';


const Videos = ({ values }) => {
  const lang = useContext(LangContext);
  const np = lang.state.np;
  const [isOpen, setOpen] = useState(false)
  const [videoId, setVideoId] = useState('')
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if(inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <motion.div
    ref={ref}
    animate={controls}
    initial="hidden"
    variants={Animation}
    >

    <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId={videoId} onClose={() => setOpen(false)} />
    <div className='videosSectionContainer'>
      <div className='videosHeaderContainer'>
          <h2 className='videosHeader'>{np? 'भिडियोहरू' : 'Videos'}</h2>
          <p className='videosSubHeader'>{np? 'हाम्रो अन्तर्वार्ता, सामाजिक कार्य र विज्ञापनको भर्खरै जारी गरिएको भिडियोहरू हेर्नुहोस्।' : 'Watch our latest released videos of interviews, social work and advertisement.'}</p>
      </div>

      <div className='videosListContainer'>

      {window.screen.width > 600 ? (
        values?.map((value) => 
          <div key={value.id} onClick={() => { setVideoId(`${value.youtube_link}`); setOpen(true); }} className='videoItemContainer'>
          <img className='videoThumbnail' src={value.featured_image} alt={value.slug} />
          <img className='videoPlayButton' onClick={()=> { setVideoId(`${value.youtube_link}`); setOpen(true); }} src={playButton} alt="playButton" />
          </div>
      )
      ) : (
        values?.slice(0, 1).map((value) => 
        <div key={value.id} onClick={() => { setVideoId(`${value.youtube_link}`); setOpen(true); }} className='videoItemContainer'>
            <img className='videoThumbnail' src={value.featured_image} alt={value.slug} />
            <img className='videoPlayButton' onClick={()=> { setVideoId(`${value.youtube_link}`); setOpen(true); }} src={playButton} alt="playButton" />
        </div>
        )
      )}




      </div>
    </div>

    <Link to="/videos">
      <Box className='MoreBTN' component='div'>
        <Typography component='span'>{np? 'अझ धेरै हेर्नुहोस्' : 'Watch More'}</Typography>
      </Box>
    </Link>

    </motion.div>
    
  )
}

export default Videos