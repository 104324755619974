import React, { useState, useEffect, useRef, useContext } from "react";
import { LangContext } from "../../context/LangContext";
import striptags from "striptags";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Animation from "../../utils/Animation";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Carousel from "better-react-carousel";

import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";

import { Tabs as TabsMui } from "@mui/material";
import { Tab as TabMui } from "@mui/material";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "./servicetabs.css";

// import required modules
import { Autoplay, Navigation, Scrollbar, A11y, Controller } from "swiper";
import know from "../../assets/img/know1.png";
import nextIcon from "../../assets/img/nextIcon.png";
import prevIcon from "../../assets/img/prevIcon.png";

import esewa from "../../assets/img/partnerEsewa.png";
import f from "../../assets/img/partnerF1.png";
import ipsL from "../../assets/img/ipsL.png";
import khaltiL from "../../assets/img/khaltiL.png";
import nepsL from "../../assets/img/nepsL.png";
import cellpayL from "../../assets/img/cellpayL.png";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      src={nextIcon}
      alt="next"
      style={{ ...style, height: "30px", width: "auto" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      src={prevIcon}
      alt="next"
      style={{ ...style, height: "30px", width: "auto" }}
      onClick={onClick}
    />
  );
}

const ServiceTabs = ({ values }) => {
  console.log("V+________", values);
  const lang = useContext(LangContext);
  const np = lang.state.np;
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const value1 = values[0];
  const value2 = values[1];
  const value3 = values[2];
  const value4 = values[3];
  const value5 = values[4];
  // const value5 = values[4];
  // parent slick slider
  const settings = {
    className: "slider variable-width",
    variableWidth: true,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // const CustomTabList = ({children, ...otherProps}) => {
  //   return
  //   <TabList {...otherProps}>
  //     <Carousel cols={1} rows={1} gap={10} loop>

  //     </Carousel>
  //   </TabList>
  // };

  const CustomTab = ({ onClick, children }) => {
    return <div onClick={onClick}>{children}</div>;
  };
  CustomTab.tabsRole = "Tab";

  const [muiTabIndex, setMuiTabIndex] = useState(0);

  // const [valueCards, setValueCards] = React.useState(0);

  // const handleChangeCards = (event, newValue) => {
  //   setValueCards(newValue);
  // };

  const [valueAccounts, setValueAccounts] = React.useState(0);

  const handleChangeAccounts = (event, newValue) => {
    setValueAccounts(newValue);
  };

  // console.log("valueAccounts", typeof valueAccounts, valueAccounts);

  // const handleChangeResetAccounts = () => {
  //   setValueAccounts(0);
  // };

  // const [valueLoans, setValueLoans] = React.useState(0);

  // const handleChangeLoans = (event, newValue) => {
  //   setValueLoans(newValue);
  // };

  // const [valueSafe, setValueSafe] = React.useState(0);

  // const handleChangeSafe = (event, newValue) => {
  //   setValueSafe(newValue);
  // };

  const [cardsTabIndex, setCardsTabIndex] = useState(0);
  const [accountsTabIndex, setAccountsTabIndex] = useState(0);
  const [loansTabIndex, setLoansTabIndex] = useState(0);
  const [safeDepo, setSafeDepo] = useState(0);

  // const [currentTab, setCurrentTab] = useState('');
  const [cardsCurrentTab, setCardsCurrentTab] = useState("");
  const [accountsCurrentTab, setAccountsCurrentTab] = useState("");
  const [loansCurrentTab, setLoansCurrentTab] = useState("");

  // useEffect(() => {
  //     setCurrentTab(tabIndex)
  // }, [tabIndex])

  useEffect(() => {
    setCardsCurrentTab(cardsTabIndex);
  }, [cardsTabIndex]);

  useEffect(() => {
    setAccountsCurrentTab(accountsTabIndex);
  }, [accountsTabIndex]);

  useEffect(() => {
    setLoansCurrentTab(loansTabIndex);
  }, [loansTabIndex]);

  useEffect(() => {
    setSafeDepo(safeDepo);
  }, [safeDepo]);

  // const activeTab = (index) => `${currentTab === index && "active-service-tab"}`;

  const cardsActiveTab = (index) =>
    `${cardsCurrentTab === index && "active-service-tab"}`;
  const accountsActiveTab = (index) =>
    `${accountsCurrentTab === index && "active-service-tab"}`;
  const loansActiveTab = (index) =>
    `${loansCurrentTab === index && "active-service-tab"}`;

  const [swiper, setSwiper] = useState();
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  return (
    <div className="bgSColor">
      <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={Animation}
        className="servicesContainer"
      >
        <div className="servicesHeaderContainer">
          <h2 className="servicecsHeader">
            {np ? "हाम्रा सेवाहरू" : "Services"}
          </h2>
          <p className="servicecsSubHeader">
            {np
              ? "विभिन्न प्रकारका खाता र ऋणका लागि हाम्रा विविध सेवाहरू हेर्नुहोस्"
              : "Check out our diversed services for different types of accounts & loans"}
          </p>
        </div>

        {/* tabs */}

        <Tabs className="servicesTabsContainer" defaultIndex={1}>
          <TabList className="servicesTabsButtonContainer">
            {values?.map((value) => (
              <Tab
                key={value.order}
                className="servicesTabButton"
                onClick={() => setValueAccounts(0)}
              >
                {np
                  ? value.title_nepali.length === 0
                    ? value.title
                    : value.title_nepali
                  : value.title}
              </Tab>
            ))}
          </TabList>

          {/* Digital Services Tab Panel */}

          <TabPanel key={value1.rank}>
            <div className="tabButtonDesc">
              <img
                className="tabButtonDescImg"
                src={value1.featured_image}
                alt={value1.slug}
              />
              <div className="tabButtonDescFlex">
                <h3 className="tabButtonDescHead">
                  {np
                    ? value1?.title_nepali.length === 0
                      ? value1?.title
                      : value1?.title_nepali
                    : value1?.title}
                </h3>
                {np ? (
                  value1.description === 0 ? (
                    <p
                      className="tabButtonDescPara"
                      dangerouslySetInnerHTML={{ __html: value1?.description }}
                    ></p>
                  ) : (
                    <p
                      className="tabButtonDescPara"
                      dangerouslySetInnerHTML={{
                        __html: value1?.description_nepali,
                      }}
                    ></p>
                  )
                ) : (
                  <p
                    className="tabButtonDescPara"
                    dangerouslySetInnerHTML={{ __html: value1?.description }}
                  ></p>
                )}
              </div>
            </div>

            <Tabs
              selectedIndex={accountsTabIndex}
              onSelect={(index) => setCardsTabIndex(index)}
            >
              <TabsMui
                className="muiTabContainer"
                value={valueAccounts}
                onChange={handleChangeAccounts}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="scrollable force tabs example"
              >
                {value1?.subcategory.map((suba, index) => (
                  <TabMui
                    key={index}
                    className="muiTabHeader"
                    label={np ? suba.title_nepali : suba.title}
                  />
                ))}

                <TabMui
                  key={6}
                  className="muiTabHeader"
                  label={
                    np
                      ? "अनलाइन भुक्तानी साझेदारहरू"
                      : "Online Payment Partners"
                  }
                />
              </TabsMui>

              {value1?.subcategory.map((subb, index) => (
                <div
                  style={{ display: "none" }}
                  className={valueAccounts == index && `muiDisplayBlock`}
                  key={index}
                >
                  <div className="serviceCardContainer">
                    <Swiper
                      modules={[
                        Autoplay,
                        Navigation,
                        Scrollbar,
                        A11y,
                        Controller,
                      ]}
                      className="external-buttons serviceCardSwiperContainer"
                      slidesPerView={1}
                      spaceBetween={35}
                      autoplay={{
                        delay: 4000,
                        disableOnInteraction: false,
                      }}
                      speed={2000}
                      // navigation={{
                      //     prevEl: prevRef?.current,
                      //     nextEl: nextRef?.current
                      // }}
                      // ref={sliderRef}
                      onInit={(swiper) => {
                        swiper.params.navigation.prevEl = prevRef.current;
                        swiper.params.navigation.nextEl = nextRef.current;
                        swiper.navigation.init();
                        swiper.navigation.update();
                      }}
                      breakpoints={{
                        640: {
                          slidesPerView: 1,
                          spaceBetween: 20,
                        },
                        768: {
                          slidesPerView: 2,
                          spaceBetween: 40,
                        },
                        1024: {
                          slidesPerView: 3,
                          spaceBetween: 50,
                        },
                      }}
                      updateOnWindowResize
                      observer
                      observeParents
                      onSwiper={setSwiper}
                    >
                      {subb.services.length > 0 ? (
                        subb.services.map((subc, index) => (
                          <SwiperSlide key={index} className="serviceCard">
                            <h5 className="cardHead">
                              {np
                                ? subc.title_nepali.length === 0
                                  ? subc.title
                                  : subc.title_nepali
                                : subc.title}
                            </h5>
                            {np ? (
                              subc.description_nepali.length === 0 ? (
                                <p
                                  className="cardDesc"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      subc.description.substring(0, 100) +
                                      "...",
                                  }}
                                ></p>
                              ) : (
                                <p
                                  className="cardDesc"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      subc.description_nepali.substring(
                                        0,
                                        100
                                      ) + "...",
                                  }}
                                ></p>
                              )
                            ) : (
                              <p
                                className="cardDesc"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    subc.description.substring(0, 100) + "...",
                                }}
                              ></p>
                            )}
                            <div className="serviceCardButtonContainer">
                              {subc?.external_link.length > 0 ? (
                                <a
                                  href={subc.external_link}
                                  target="_blank"
                                  className="serviceKnowContainer"
                                >
                                  <a className="cardKnowButton">
                                    {np ? "थप जान्नुहोस्" : "Know More"}
                                  </a>
                                  <img className="serviceKnowImg" src={know} />
                                </a>
                              ) : (
                                <Link
                                  to={`/service-details/${subc.slug}`}
                                  className="serviceKnowContainer"
                                >
                                  <a className="cardKnowButton">
                                    {np ? "थप जान्नुहोस्" : "Know More"}
                                  </a>
                                  <img className="serviceKnowImg" src={know} />
                                </Link>
                              )}
                            </div>
                          </SwiperSlide>
                        ))
                      ) : (
                        <SwiperSlide className="serviceCard">
                          <h5 className="cardHead">
                            {np
                              ? "माफ गर्नुहोस्, कुनै सेवा उपलब्ध छैन"
                              : "Sorry, no services available"}
                          </h5>
                        </SwiperSlide>
                      )}

                      <div className="servicesSliderContainer">
                        <div ref={prevRef}>
                          <IoIosArrowDropleftCircle className="serviceSlideIcon swiper-button" />
                        </div>
                        <div ref={nextRef}>
                          <IoIosArrowDroprightCircle className="serviceSlideIcon swiper-button" />
                        </div>
                      </div>
                    </Swiper>
                  </div>
                </div>
              ))}

              {/* start static */}
              <div
                key={6}
                style={{ display: "none" }}
                className={valueAccounts == "6" && `muiDisplayBlock`}
              >
                <div className="serviceCardContainer">
                  <Swiper
                    modules={[
                      Autoplay,
                      Navigation,
                      Scrollbar,
                      A11y,
                      Controller,
                    ]}
                    className="external-buttons serviceCardSwiperContainer"
                    slidesPerView={1}
                    spaceBetween={35}
                    autoplay={{
                      delay: 4000,
                      disableOnInteraction: false,
                    }}
                    speed={2000}
                    // navigation={{
                    //     prevEl: prevRef?.current,
                    //     nextEl: nextRef?.current
                    // }}
                    // ref={sliderRef}
                    onInit={(swiper) => {
                      swiper.params.navigation.prevEl = prevRef.current;
                      swiper.params.navigation.nextEl = nextRef.current;
                      swiper.navigation.init();
                      swiper.navigation.update();
                    }}
                    breakpoints={{
                      640: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                      },
                      768: {
                        slidesPerView: 2,
                        spaceBetween: 40,
                      },
                      1024: {
                        slidesPerView: 4,
                        spaceBetween: 50,
                      },
                    }}
                    updateOnWindowResize
                    observer
                    observeParents
                    onSwiper={setSwiper}
                  >
                    <SwiperSlide key={1}>
                      <img src={nepsL} alt="nepsL" />
                    </SwiperSlide>

                    <SwiperSlide key={2}>
                      <img src={f} alt="f" />
                    </SwiperSlide>

                    <SwiperSlide key={3}>
                      <img src={esewa} alt="esewa" />
                    </SwiperSlide>

                    <SwiperSlide key={4}>
                      <img src={ipsL} alt="ipsL" />
                    </SwiperSlide>

                    <SwiperSlide key={5}>
                      <img src={khaltiL} alt="khaltiL" />
                    </SwiperSlide>

                    <SwiperSlide key={6}>
                      <img src={cellpayL} alt="cellpayL" />
                    </SwiperSlide>

                    <div className="servicesSliderContainer">
                      <div ref={prevRef}>
                        <IoIosArrowDropleftCircle className="serviceSlideIcon swiper-button" />
                      </div>
                      <div ref={nextRef}>
                        <IoIosArrowDroprightCircle className="serviceSlideIcon swiper-button" />
                      </div>
                    </div>
                  </Swiper>
                </div>
              </div>
              {/* end static */}
            </Tabs>
            <Link to="/service/cards">
              <Box className="SMoreBTN" component="div">
                <Typography component="span">
                  {np
                    ? "सबै डिजिटल सेवाहरू ब्राउज गर्नुहोस्"
                    : "Browse All Digital Services"}
                </Typography>
              </Box>
            </Link>
          </TabPanel>

          {/* Accounts Tab Panel */}
          <TabPanel key={value2.rank}>
            <div className="tabButtonDesc">
              <img
                className="tabButtonDescImg"
                src={value2.featured_image}
                alt={value2.slug}
              />
              <div className="tabButtonDescFlex">
                <h3 className="tabButtonDescHead">
                  {np
                    ? value2?.title_nepali.length === 0
                      ? value2?.title
                      : value2?.title_nepali
                    : value2?.title}
                </h3>
                <p className="tabButtonDescPara">
                  {np
                    ? value2.description === 0
                      ? striptags(value2?.description)
                      : striptags(value2?.description_nepali)
                    : striptags(value2?.description)}
                </p>
              </div>
            </div>

            <Tabs
              selectedIndex={accountsTabIndex}
              onSelect={(index) => setAccountsTabIndex(index)}
            >
              <TabsMui
                className="muiTabContainer"
                value={valueAccounts}
                onChange={handleChangeAccounts}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="scrollable force tabs example"
              >
                {value2?.subcategory.map((suba, index) => (
                  <TabMui
                    key={index}
                    className="muiTabHeader"
                    label={np ? suba.title_nepali : suba.title}
                  />
                ))}
              </TabsMui>

              {value2?.subcategory.map((subb, index) => (
                <div
                  style={{ display: "none" }}
                  className={valueAccounts == index && `muiDisplayBlock`}
                  key={index}
                >
                  <div className="serviceCardContainer">
                    <Swiper
                      modules={[
                        Autoplay,
                        Navigation,
                        Scrollbar,
                        A11y,
                        Controller,
                      ]}
                      className="external-buttons serviceCardSwiperContainer"
                      slidesPerView={1}
                      spaceBetween={35}
                      autoplay={{
                        delay: 4000,
                        disableOnInteraction: false,
                      }}
                      speed={2000}
                      // navigation={{
                      //     prevEl: prevRef?.current,
                      //     nextEl: nextRef?.current
                      // }}
                      // ref={sliderRef}
                      onInit={(swiper) => {
                        swiper.params.navigation.prevEl = prevRef.current;
                        swiper.params.navigation.nextEl = nextRef.current;
                        swiper.navigation.init();
                        swiper.navigation.update();
                      }}
                      breakpoints={{
                        640: {
                          slidesPerView: 1,
                          spaceBetween: 20,
                        },
                        768: {
                          slidesPerView: 2,
                          spaceBetween: 40,
                        },
                        1024: {
                          slidesPerView: 3,
                          spaceBetween: 50,
                        },
                      }}
                      updateOnWindowResize
                      observer
                      observeParents
                      onSwiper={setSwiper}
                    >
                      {subb.services.length > 0 ? (
                        subb.services.map((subc, index) => (
                          <SwiperSlide key={index} className="serviceCard">
                            <h5 className="cardHead">
                              {np
                                ? subc.title_nepali.length === 0
                                  ? subc.title
                                  : subc.title_nepali
                                : subc.title}
                            </h5>
                            {np ? (
                              subc.description_nepali.length === 0 ? (
                                <p
                                  className="cardDesc"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      subc.description.substring(0, 100) +
                                      "...",
                                  }}
                                ></p>
                              ) : (
                                <p
                                  className="cardDesc"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      subc.description_nepali.substring(
                                        0,
                                        100
                                      ) + "...",
                                  }}
                                ></p>
                              )
                            ) : (
                              <p
                                className="cardDesc"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    subc.description.substring(0, 100) + "...",
                                }}
                              ></p>
                            )}
                            <div className="serviceCardButtonContainer">
                              {subc?.external_link.length > 0 ? (
                                <a
                                  href={subc.external_link}
                                  target="_blank"
                                  className="serviceKnowContainer"
                                >
                                  <a className="cardKnowButton">
                                    {np ? "थप जान्नुहोस्" : "Know More"}
                                  </a>
                                  <img className="serviceKnowImg" src={know} />
                                </a>
                              ) : (
                                <Link
                                  to={`/service-details/${subc.slug}`}
                                  className="serviceKnowContainer"
                                >
                                  <a className="cardKnowButton">
                                    {np ? "थप जान्नुहोस्" : "Know More"}
                                  </a>
                                  <img className="serviceKnowImg" src={know} />
                                </Link>
                              )}
                            </div>
                          </SwiperSlide>
                        ))
                      ) : (
                        <SwiperSlide className="serviceCard">
                          <h5 className="cardHead">
                            {np
                              ? "माफ गर्नुहोस्, कुनै सेवा उपलब्ध छैन"
                              : "Sorry, no services available"}
                          </h5>
                        </SwiperSlide>
                      )}
                      <div className="servicesSliderContainer">
                        <div ref={prevRef}>
                          <IoIosArrowDropleftCircle className="serviceSlideIcon swiper-button" />
                        </div>
                        <div ref={nextRef}>
                          <IoIosArrowDroprightCircle className="serviceSlideIcon swiper-button" />
                        </div>
                      </div>
                    </Swiper>
                  </div>
                </div>
              ))}
            </Tabs>
            <Link to="/service/accounts">
              <Box className="SMoreBTN" component="div">
                <Typography component="span">
                  {np
                    ? "सबै खाता सेवाहरू ब्राउज गर्नुहोस्"
                    : "Browse All Accounts Services"}
                </Typography>
              </Box>
            </Link>
          </TabPanel>

          {/* Loans Service Tab Panel */}

          <TabPanel key={value3.rank}>
            <div className="tabButtonDesc">
              <img
                className="tabButtonDescImg"
                src={value3.featured_image}
                alt={value3.slug}
              />
              <div className="tabButtonDescFlex">
                <h3 className="tabButtonDescHead">
                  {np
                    ? value3?.title_nepali.length === 0
                      ? value3?.title
                      : value3?.title_nepali
                    : value3?.title}
                </h3>
                <p className="tabButtonDescPara">
                  {np
                    ? value3.description === 0
                      ? striptags(value3?.description)
                      : striptags(value3?.description_nepali)
                    : striptags(value3?.description)}
                </p>
              </div>
            </div>

            <Tabs
              selectedIndex={accountsTabIndex}
              onSelect={(index) => setLoansTabIndex(index)}
            >
              <TabsMui
                className="muiTabContainer"
                value={valueAccounts}
                onChange={handleChangeAccounts}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="scrollable force tabs example"
              >
                {value3?.subcategory.map((suba, index) => (
                  <TabMui
                    key={index}
                    className="muiTabHeader"
                    label={np ? suba.title_nepali : suba.title}
                  />
                ))}
              </TabsMui>

              {value3?.subcategory.map((subb, index) => (
                <div
                  style={{ display: "none" }}
                  className={valueAccounts == index && `muiDisplayBlock`}
                  key={index}
                >
                  <div className="serviceCardContainer">
                    <Swiper
                      modules={[
                        Autoplay,
                        Navigation,
                        Scrollbar,
                        A11y,
                        Controller,
                      ]}
                      className="external-buttons serviceCardSwiperContainer"
                      slidesPerView={1}
                      spaceBetween={35}
                      autoplay={{
                        delay: 4000,
                        disableOnInteraction: false,
                      }}
                      speed={2000}
                      // navigation={{
                      //     prevEl: prevRef?.current,
                      //     nextEl: nextRef?.current
                      // }}
                      // ref={sliderRef}
                      onInit={(swiper) => {
                        swiper.params.navigation.prevEl = prevRef.current;
                        swiper.params.navigation.nextEl = nextRef.current;
                        swiper.navigation.init();
                        swiper.navigation.update();
                      }}
                      breakpoints={{
                        640: {
                          slidesPerView: 1,
                          spaceBetween: 20,
                        },
                        768: {
                          slidesPerView: 2,
                          spaceBetween: 40,
                        },
                        1024: {
                          slidesPerView: 3,
                          spaceBetween: 50,
                        },
                      }}
                      updateOnWindowResize
                      observer
                      observeParents
                      onSwiper={setSwiper}
                    >
                      {subb.services.length > 0 ? (
                        subb.services.map((subc, index) => (
                          <SwiperSlide key={index} className="serviceCard">
                            <h5 className="cardHead">
                              {np
                                ? subc.title_nepali.length === 0
                                  ? subc.title
                                  : subc.title_nepali
                                : subc.title}
                            </h5>

                            {np ? (
                              subc.description_nepali.length === 0 ? (
                                <p
                                  className="cardDesc"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      subc.description.substring(0, 100) +
                                      "...",
                                  }}
                                ></p>
                              ) : (
                                <p
                                  className="cardDesc"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      subc.description_nepali.substring(
                                        0,
                                        100
                                      ) + "...",
                                  }}
                                ></p>
                              )
                            ) : (
                              <p
                                className="cardDesc"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    subc.description.substring(0, 100) + "...",
                                }}
                              ></p>
                            )}

                            <div className="serviceCardButtonContainer">
                              {subc?.external_link.length > 0 ? (
                                <a
                                  href={subc.external_link}
                                  target="_blank"
                                  className="serviceKnowContainer"
                                >
                                  <a className="cardKnowButton">
                                    {np ? "थप जान्नुहोस्" : "Know More"}
                                  </a>
                                  <img className="serviceKnowImg" src={know} />
                                </a>
                              ) : (
                                <Link
                                  to={`/service-details/${subc.slug}`}
                                  className="serviceKnowContainer"
                                >
                                  <a className="cardKnowButton">
                                    {np ? "थप जान्नुहोस्" : "Know More"}
                                  </a>
                                  <img className="serviceKnowImg" src={know} />
                                </Link>
                              )}
                            </div>
                          </SwiperSlide>
                        ))
                      ) : (
                        <SwiperSlide className="serviceCard">
                          <h5 className="cardHead">
                            {np
                              ? "माफ गर्नुहोस्, कुनै सेवा उपलब्ध छैन"
                              : "Sorry, no services available"}
                          </h5>
                        </SwiperSlide>
                      )}
                      <div className="servicesSliderContainer">
                        <div ref={prevRef}>
                          <IoIosArrowDropleftCircle className="serviceSlideIcon swiper-button" />
                        </div>
                        <div ref={nextRef}>
                          <IoIosArrowDroprightCircle className="serviceSlideIcon swiper-button" />
                        </div>
                      </div>
                    </Swiper>
                  </div>
                </div>
              ))}
            </Tabs>
            <Link to="/service/loans">
              <Box className="SMoreBTN" component="div">
                <Typography component="span">
                  {np
                    ? "सबै कर्जा सेवाहरू ब्राउज गर्नुहोस्"
                    : "Browse All Loans Services"}
                </Typography>
              </Box>
            </Link>
          </TabPanel>

          {/* Safe Deposit locker Tab Panel */}
          <TabPanel key={value4.rank}>
            <div className="tabButtonDesc">
              <img
                className="tabButtonDescImg"
                src={value4.featured_image}
                alt={value4.slug}
              />
              <div className="tabButtonDescFlex">
                <h3 className="tabButtonDescHead">
                  {np
                    ? value4?.title_nepali.length === 0
                      ? value4?.title
                      : value4?.title_nepali
                    : value4?.title}
                </h3>
                <p className="tabButtonDescPara">
                  {np
                    ? value4.description === 0
                      ? striptags(value4?.description)
                      : striptags(value4?.description_nepali)
                    : striptags(value4?.description)}
                </p>
              </div>
            </div>

            <Tabs
              selectedIndex={accountsTabIndex}
              onSelect={(index) => setSafeDepo(index)}
            >
              <TabsMui
                className="muiTabContainer"
                value={valueAccounts}
                onChange={handleChangeAccounts}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="scrollable force tabs example"
              >
                {value4?.subcategory.map((suba, index) => (
                  <TabMui
                    key={index}
                    className="muiTabHeader"
                    label={np ? suba.title_nepali : suba.title}
                  />
                ))}
              </TabsMui>

              {value4?.subcategory.map((subb, index) => (
                <div
                  style={{ display: "none" }}
                  className={valueAccounts == index && `muiDisplayBlock`}
                  key={index}
                >
                  <div className="serviceCardContainer">
                    <Swiper
                      modules={[
                        Autoplay,
                        Navigation,
                        Scrollbar,
                        A11y,
                        Controller,
                      ]}
                      className="external-buttons serviceCardSwiperContainer"
                      slidesPerView={1}
                      spaceBetween={35}
                      autoplay={{
                        delay: 4000,
                        disableOnInteraction: false,
                      }}
                      speed={2000}
                      // navigation={{
                      //     prevEl: prevRef?.current,
                      //     nextEl: nextRef?.current
                      // }}
                      // ref={sliderRef}
                      onInit={(swiper) => {
                        swiper.params.navigation.prevEl = prevRef.current;
                        swiper.params.navigation.nextEl = nextRef.current;
                        swiper.navigation.init();
                        swiper.navigation.update();
                      }}
                      breakpoints={{
                        640: {
                          slidesPerView: 1,
                          spaceBetween: 20,
                        },
                        768: {
                          slidesPerView: 2,
                          spaceBetween: 40,
                        },
                        1024: {
                          slidesPerView: 3,
                          spaceBetween: 50,
                        },
                      }}
                      updateOnWindowResize
                      observer
                      observeParents
                      onSwiper={setSwiper}
                    >
                      {subb.services.length > 0 ? (
                        subb.services.map((subc, index) => (
                          <SwiperSlide key={index} className="serviceCard">
                            <h5 className="cardHead">
                              {np
                                ? subc.title_nepali.length === 0
                                  ? subc.title
                                  : subc.title_nepali
                                : subc.title}
                            </h5>
                            {np ? (
                              subc.description_nepali.length === 0 ? (
                                <p
                                  className="cardDesc"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      subc.description.substring(0, 100) +
                                      "...",
                                  }}
                                ></p>
                              ) : (
                                <p
                                  className="cardDesc"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      subc.description_nepali.substring(
                                        0,
                                        100
                                      ) + "...",
                                  }}
                                ></p>
                              )
                            ) : (
                              <p
                                className="cardDesc"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    subc.description.substring(0, 100) + "...",
                                }}
                              ></p>
                            )}
                            <div className="serviceCardButtonContainer">
                              {subc?.external_link.length > 0 ? (
                                <a
                                  href={subc.external_link}
                                  target="_blank"
                                  className="serviceKnowContainer"
                                >
                                  <a className="cardKnowButton">
                                    {np ? "थप जान्नुहोस्" : "Know More"}
                                  </a>
                                  <img className="serviceKnowImg" src={know} />
                                </a>
                              ) : (
                                <Link
                                  to={`/service-details/${subc.slug}`}
                                  className="serviceKnowContainer"
                                >
                                  <a className="cardKnowButton">
                                    {np ? "थप जान्नुहोस्" : "Know More"}
                                  </a>
                                  <img className="serviceKnowImg" src={know} />
                                </Link>
                              )}
                            </div>
                          </SwiperSlide>
                        ))
                      ) : (
                        <SwiperSlide className="serviceCard">
                          <h5 className="cardHead">
                            {np
                              ? "माफ गर्नुहोस्, कुनै सेवा उपलब्ध छैन"
                              : "Sorry, no services available"}
                          </h5>
                        </SwiperSlide>
                      )}
                      <div className="servicesSliderContainer">
                        <div ref={prevRef}>
                          <IoIosArrowDropleftCircle className="serviceSlideIcon swiper-button" />
                        </div>
                        <div ref={nextRef}>
                          <IoIosArrowDroprightCircle className="serviceSlideIcon swiper-button" />
                        </div>
                      </div>
                    </Swiper>
                  </div>
                </div>
              ))}
            </Tabs>
            <Link to="/service/safe-deposit-locker">
              <Box className="SMoreBTN" component="div">
                <Typography component="span">
                  {np
                    ? "सबै सुरक्षित लकर सेवाहरू ब्राउज गर्नुहोस्"
                    : "Browse All Safe Deposit Locker"}
                </Typography>
              </Box>
            </Link>
          </TabPanel>
          <TabPanel key={value5.rank}>
            <div className="tabButtonDesc">
              <img
                className="tabButtonDescImg"
                src={value5.featured_image}
                alt={value5.slug}
              />
              <div className="tabButtonDescFlex">
                <h3 className="tabButtonDescHead">
                  {np
                    ? value5?.title_nepali.length === 0
                      ? value5?.title
                      : value5?.title_nepali
                    : value5?.title}
                </h3>
                <p className="tabButtonDescPara">
                  {np
                    ? value5.description === 0
                      ? striptags(value5?.description)
                      : striptags(value5?.description_nepali)
                    : striptags(value5?.description)}
                </p>
              </div>
            </div>

            <Tabs
              selectedIndex={accountsTabIndex}
              onSelect={(index) => setSafeDepo(index)}
            >
              <TabsMui
                className="muiTabContainer"
                value={valueAccounts}
                onChange={handleChangeAccounts}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="scrollable force tabs example"
              >
                {value5?.subcategory.map((suba, index) => (
                  <TabMui
                    key={index}
                    className="muiTabHeader"
                    label={np ? suba.title_nepali : suba.title}
                  />
                ))}
              </TabsMui>

              {value5?.subcategory.map((subb, index) => (
                <div
                  style={{ display: "none" }}
                  className={valueAccounts == index && `muiDisplayBlock`}
                  key={index}
                >
                  <div className="serviceCardContainer">
                    <Swiper
                      modules={[
                        Autoplay,
                        Navigation,
                        Scrollbar,
                        A11y,
                        Controller,
                      ]}
                      className="external-buttons serviceCardSwiperContainer"
                      slidesPerView={1}
                      spaceBetween={35}
                      autoplay={{
                        delay: 4000,
                        disableOnInteraction: false,
                      }}
                      speed={2000}
                      // navigation={{
                      //     prevEl: prevRef?.current,
                      //     nextEl: nextRef?.current
                      // }}
                      // ref={sliderRef}
                      onInit={(swiper) => {
                        swiper.params.navigation.prevEl = prevRef.current;
                        swiper.params.navigation.nextEl = nextRef.current;
                        swiper.navigation.init();
                        swiper.navigation.update();
                      }}
                      breakpoints={{
                        640: {
                          slidesPerView: 1,
                          spaceBetween: 20,
                        },
                        768: {
                          slidesPerView: 2,
                          spaceBetween: 40,
                        },
                        1024: {
                          slidesPerView: 3,
                          spaceBetween: 50,
                        },
                      }}
                      updateOnWindowResize
                      observer
                      observeParents
                      onSwiper={setSwiper}
                    >
                      {subb.services.length > 0 ? (
                        subb.services.map((subc, index) => (
                          <SwiperSlide key={index} className="serviceCard">
                            <h5 className="cardHead">
                              {np
                                ? subc.title_nepali.length === 0
                                  ? subc.title
                                  : subc.title_nepali
                                : subc.title}
                            </h5>
                            {np ? (
                              subc.description_nepali.length === 0 ? (
                                <p
                                  className="cardDesc"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      subc.description.substring(0, 100) +
                                      "...",
                                  }}
                                ></p>
                              ) : (
                                <p
                                  className="cardDesc"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      subc.description_nepali.substring(
                                        0,
                                        100
                                      ) + "...",
                                  }}
                                ></p>
                              )
                            ) : (
                              <p
                                className="cardDesc"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    subc.description.substring(0, 100) + "...",
                                }}
                              ></p>
                            )}
                            <div className="serviceCardButtonContainer">
                              {subc?.external_link.length > 0 ? (
                                <a
                                  href={subc.external_link}
                                  target="_blank"
                                  className="serviceKnowContainer"
                                >
                                  <a className="cardKnowButton">
                                    {np ? "थप जान्नुहोस्" : "Know More"}
                                  </a>
                                  <img className="serviceKnowImg" src={know} />
                                </a>
                              ) : (
                                <Link
                                  to={`/service-details/${subc.slug}`}
                                  className="serviceKnowContainer"
                                >
                                  <a className="cardKnowButton">
                                    {np ? "थप जान्नुहोस्" : "Know More"}
                                  </a>
                                  <img className="serviceKnowImg" src={know} />
                                </Link>
                              )}
                            </div>
                          </SwiperSlide>
                        ))
                      ) : (
                        <SwiperSlide className="serviceCard">
                          <h5 className="cardHead">
                            {np
                              ? "माफ गर्नुहोस्, कुनै सेवा उपलब्ध छैन"
                              : "Sorry, no services available"}
                          </h5>
                        </SwiperSlide>
                      )}
                      <div className="servicesSliderContainer">
                        <div ref={prevRef}>
                          <IoIosArrowDropleftCircle className="serviceSlideIcon swiper-button" />
                        </div>
                        <div ref={nextRef}>
                          <IoIosArrowDroprightCircle className="serviceSlideIcon swiper-button" />
                        </div>
                      </div>
                    </Swiper>
                  </div>
                </div>
              ))}
            </Tabs>
            <Link to="/service/safe-deposit-locker">
              <Box className="SMoreBTN" component="div">
                <Typography component="span">
                  {np
                    ? "सबै सुरक्षित लकर सेवाहरू ब्राउज गर्नुहोस्"
                    : "Browse All Safe Deposit Locker"}
                </Typography>
              </Box>
            </Link>
          </TabPanel>

          {/* Online Services Tab Panel */}
          {/* <TabPanel key={value5.rank}>
          <div className='tabButtonDesc'>
              <img className='tabButtonDescImg' src={value5.featured_image} alt={value5.slug} />
              <div className='tabButtonDescFlex'>
              <h3 className='tabButtonDescHead'>
                {np ? (
                  value5?.title_nepali.length === 0? (
                    value5?.title
                  ) : (
                    value5?.title_nepali
                  )
                ) : ( 
                  value5?.title
                )}
              </h3>
              <p className='tabButtonDescPara'>
                {np ? (
                  value5.description === 0? (
                    striptags(value5?.description)
                  ) : (
                    striptags(value5?.description_nepali)
                  )
                ) : (
                  striptags(value5?.description)
                )}
              </p>
              </div>
          </div>

          <Tabs  
            selectedIndex={accountsTabIndex}
            onSelect={index => setSafeDepo(index)}
          >

        <TabsMui
          className="muiTabContainer"
          value={valueAccounts}
          onChange={handleChangeAccounts}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="scrollable force tabs example"
        >
            {value5?.subcategory.map((suba, index) =>
                <TabMui 
                key={index} 
                className="muiTabHeader" 
                label={
                  np?(
                    suba.title_nepali
                  ):(
                    suba.title
                  )
                  
                }  
                />  
            )}
        </TabsMui>

        {value5?.subcategory.map((subb, index) => 
                    <div style={{display: 'none'}} className={valueAccounts == index&&`muiDisplayBlock`} key={index}>
                    <div className='serviceCardContainer'>
                        <Swiper
                            modules={[Autoplay, Navigation, Scrollbar, A11y, Controller]}
                            className="external-buttons serviceCardSwiperContainer"
                            slidesPerView={1}
                            spaceBetween={35}
                            autoplay={{
                              delay: 4000,
                              disableOnInteraction: false,
                            }}
                            speed={2000}
                            // navigation={{
                            //     prevEl: prevRef?.current,
                            //     nextEl: nextRef?.current
                            // }}
                            // ref={sliderRef}
                            onInit={(swiper) => {
                              swiper.params.navigation.prevEl = prevRef.current;
                              swiper.params.navigation.nextEl = nextRef.current;
                              swiper.navigation.init();
                              swiper.navigation.update();
                            }}
                            breakpoints={{
                                640: {
                                  slidesPerView: 1,
                                  spaceBetween: 20,
                                },
                                768: {
                                  slidesPerView: 2,
                                  spaceBetween: 40,
                                },
                                1024: {
                                  slidesPerView: 3,
                                  spaceBetween: 50,
                                },
                            }}
                            updateOnWindowResize
                            observer
                            observeParents
                            onSwiper={setSwiper}
                        >

                            { subb.services.length > 0 ? (subb.services.map((subc, index) => 
                            <SwiperSlide key={index} className='serviceCard'>
                            <h5 className='cardHead'>
                              {np? (
                                subc.title_nepali.length === 0? (
                                  subc.title
                                ) : (
                                  subc.title_nepali
                                )
                              ) :(
                                subc.title
                              )}
                            </h5>
                            {np? (
                                  subc.description_nepali.length === 0? (
                                    <p 
                                    className='cardDesc'
                                    dangerouslySetInnerHTML={{ __html: subc.description.substring(0, 100)+'...' }}
                                    ></p>
                                  ) : (
                                    <p 
                                    className='cardDesc'
                                    dangerouslySetInnerHTML={{ __html: subc.description_nepali.substring(0, 100)+'...' }}
                                    ></p>
                                  )
                                ) : (
                                  <p 
                                  className='cardDesc'
                                  dangerouslySetInnerHTML={{ __html: subc.description.substring(0, 100)+'...' }}
                                  ></p>
                                )}
                            <div className='serviceCardButtonContainer'>

                            {subc?.external_link.length > 0 ? (
                              <a
                              href={subc.external_link}
                              target="_blank"
                              className='serviceKnowContainer'>
                              <a className='cardKnowButton'>
                                {np? (
                                  "थप जान्नुहोस्"
                                ) : (
                                  "Know More"
                                )}            
                              </a>
                              <img
                                  className='serviceKnowImg'
                                  src={know}
                              />
                              </a>
                            ):(
                              <Link
                              to={`/service-details/${subc.slug}`} 
                              className='serviceKnowContainer'>
                              <a className='cardKnowButton'>
                                {np? (
                                  "थप जान्नुहोस्"
                                ) : (
                                  "Know More"
                                )}            
                              </a>
                              <img
                                  className='serviceKnowImg'
                                  src={know}
                              />
                              </Link>
                            )}

                            </div>
                           </SwiperSlide>    

                            )) : (
                              <SwiperSlide className='serviceCard'>
                              <h5 className='cardHead'>
                                {np? (
                                  "माफ गर्नुहोस्, कुनै सेवा उपलब्ध छैन"
                                ) : (
                                  "Sorry, no services available"
                                )}
                                </h5>
                             </SwiperSlide>
                            )
                            }
                        <div className='servicesSliderContainer'>
                              <div ref={prevRef}>
                                  <IoIosArrowDropleftCircle className="serviceSlideIcon swiper-button" />
                              </div>
                              <div ref={nextRef}>
                                  <IoIosArrowDroprightCircle className="serviceSlideIcon swiper-button" />
                              </div>
                          </div> 
                        </Swiper>
                        </div>

                  </div>  
                  )}


          </Tabs>
          <Link to="/service/safe-deposit-locker">
                <Box className='SMoreBTN' component='div'>
                    <Typography component='span'> 
                        {np?(
                            "सबै अनलाइन सेवाहरू ब्राउज गर्नुहोस्"
                        ):(
                            "Browse All Online Services"
                        )}
                    </Typography>
                </Box>
          </Link>
        </TabPanel>  */}
        </Tabs>
      </motion.div>
    </div>
  );
};

export default ServiceTabs;
