import React, { useState, useEffect } from "react";
import LandingSlider from "../../component/LandingSlider";
import Accordion from "../../component/Accordion";
import Tabs from "../../component/Tabs";
import "./home.css";
import HomepageModal from "../../component/HomepageModal";
import Offers from "../../component/Offers";
import MobileBank from "../../component/MobileBank";
import EpayPartners from "../../component/EpayPartners";
import CeoMessage from "../../component/CeoMessage";
import Videos from "../../component/Videos";
import ServiceTabs from "../../component/ServiceTabs";
import Interest from "../../component/Interest";
import Blogs from "../../component/Blogs";

import { ClipLoader } from "react-spinners";
import useFetchData from "../../utils/ApiFetch";
import ServiceApi from "../../component/ServiceApi";
import PopUpOnce from "../../component/PopUpOnce";
import { Link } from "react-router-dom";

import { useGetHomeQuery } from "../../features/api/apiSlice";
import { HMobiledata } from "@mui/icons-material";
import KeyContact from "../../component/keycontact";

const Home = () => {
  const [isPopTime, setIsPopTime] = useState(false);

  const { data: data, isLoading } = useGetHomeQuery();

  useEffect(() => {
    if (data && !isLoading) {
      const timer = setTimeout(() => setIsPopTime(true), 5000);
      return () => clearTimeout(timer);
    }
  }, [data, isLoading]);

  return (
    <>
      {data || !isLoading ? (
        <>
          {isPopTime && <PopUpOnce values={data.popups} />}
          <div className="pt"></div>
          <div className="row-divider">
            <div className="slider">
              <LandingSlider values={data.banners} />
            </div>
            <div className="accordion">
              <Accordion
                news={data.news}
                grievance={data.grievance_officer}
                information={data.information_officer}
                compliance={data.compliance_officer}
                secretary={data?.company_secretary}
              />
            </div>
          </div>
          <Tabs />
          <ServiceTabs values={data.service_category} />
          <Interest values={data.interest_rates} />
          <Videos values={data.videos} />
          <Offers values={data.offers_category} />
          <KeyContact
            grievance={data.grievance_officer}
            information={data.information_officer}
            compliance={data.compliance_officer}
            secretary={data?.company_secretary}
          />
          <MobileBank />
        </>
      ) : (
        <div className="loaderContainer">
          <ClipLoader size={100} color="#A93A7C" loading />{" "}
          <p>‘‘Sharing Dreams Changing Lives’’</p>
        </div>
      )}
    </>
  );
};
export default Home;
