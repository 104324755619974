const ErrorFallback = ({ error, resetErrorBoundary }) => {
  return (
    <div className="error">
      <h1 style={{ textAlign: "center", padding: "20px 0px" }}>No Data</h1>
      <h1 style={{ textAlign: "center", padding: "10px 0px" }}>
        <button style={{ padding: "5px" }} onClick={resetErrorBoundary}>
          Reload Page
        </button>
      </h1>
      {/* <p>Something went wrong:</p>
            <pre>{error.message}</pre>
            <button onClick={resetErrorBoundary}>Try again</button> */}
    </div>
  );
};
export default ErrorFallback;
