import React, { useEffect } from 'react'
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Swiper, SwiperSlide } from 'swiper/react';
import './landingSlider.css'

import Animation from '../../utils/Animation';

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Autoplay, Pagination, Navigation, EffectCreative } from "swiper";


const LandingSlider = ({ values }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if(inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <div
    ref={ref}
    animate={controls}
    initial="hidden"
    variants={Animation}
    className='landingSliderWrapper'>
    <Swiper
      autoHeight={false}
      speed={2500}
      slidesPerView={1}
      spaceBetween={0}
      loop={true}
      grabCursor={true}
      // effect={"creative"}
      // creativeEffect={{
      //   prev: {
      //     shadow: true,
      //     translate: [0, 0, -400],
      //   },
      //   next: {
      //     translate: ["100%", 0, 0],
      //   },
      // }}
      autoplay={{
        delay: 8000,
        disableOnInteraction: false,
      }}
      pagination={{
        clickable: true,
      }}
      navigation={true}
      modules={[Autoplay, Pagination, Navigation, EffectCreative]}
      className='swiper'
    >
      {values?.map((value) =>
            <SwiperSlide key={value.id} className='slide'> 
            <img
            // effect="blur"
            className='sliderImg'
            src={value.DocPath}
            alt={value.Title}    
            />
            {/* <LazyLoadImage
            effect="blur"
            className='sliderImg'
            src={value.DocPath}
            alt={value.Title}    
            /> */}
            </SwiperSlide>      
      )}

      {/* <SwiperSlide className='slide'>
      <img
        className='sliderImg'
        src={banner2}
        alt="banner"    
        />
      </SwiperSlide>
      <SwiperSlide className='slide'>
      <img
        className='sliderImg'
        src={banner3}
        alt="banner"    
        />
      </SwiperSlide>
      <SwiperSlide className='slide'>  
      <img
        className='sliderImg'
        src={banner4}
        alt="banner"    
        />
      </SwiperSlide> */}

    </Swiper>
  </div>
  )
}

export default LandingSlider