import React, { useState, useEffect, useRef, useContext } from "react";
import { LangContext } from '../../context/LangContext';
import {Link} from 'react-router-dom'
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Animation from '../../utils/Animation';
import useFetchData from '../../utils/ApiFetch';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// import required modules
import { Navigation, Scrollbar, A11y, Controller, Grid } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/navigation";
import "swiper/css/scrollbar";

import chevronRight from '../../assets/img/chevronRight.png'
import knowIconPrimary from '../../assets/img/knowIconPrimary.png'
import exclusiveOffersImage from '../../assets/img/exclusiveOffersImage.webp'

import './offers.css'

const Offers = ({ values }) => {

  const {
    data,
    loading,
    error,
  } = useFetchData('/site_settings');

  const lang = useContext(LangContext);
  const np = lang.state.np;
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if(inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const [swiper, setSwiper] = useState();
  const prevRef = useRef();
  const nextRef = useRef();

  useEffect(() => {
    if (swiper) {
      swiper.params.navigation.prevEl = prevRef.current;
      swiper.params.navigation.nextEl = nextRef.current;
      swiper.navigation.init();
      swiper.navigation.update();
    }
  }, [swiper]);

  const settings = {
    className: "center",
    // centerMode: true,
    infinite: false,
    // centerPadding: "60px",
    slidesToShow: 1,
    autoplay: false,
    speed: 500,
    rows: 2,
    slidesPerRow: 2
  };

  return (
    <>
        {data?.site_settings?.show_offer === 'Yes'&&(
      <>
      {window.screen.width > 600 ? (
    <div className='bgColor offersHide'>
        {/* tabs */}
        <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={Animation}
        >
        <Tabs 
        className='offersContainer'>
            <div className='offersHeadContainer'>
                <h3 className='offersHead'>{np? 'विशेष प्रस्तावहरू' : 'Exclusive Offers'}</h3>
                <TabList className='offersTabButtonsContainer' defaultIndex={0}>
                  {values?.map((value) => 
                      <Tab key={value.order_no} className='offersTabButton'>
                        {np?(
                          value.title_nepali.length === 0? (
                            value.title
                          ) : (
                            value.title_nepali
                          )
                        ):(
                          value.title
                        )}
                      </Tab>
                  )}

                </TabList>
            </div>

            {/* tabPanel */}

          {values?.map((value) => 
          <TabPanel key={value.order_no}>
          <div className='exclusiveOffersFlexContainer'>
            <div className='exclusiveOffersImageContainer' style={{ background: `linear-gradient(rgb(169, 58, 124,0.7), rgb(169, 58, 124,0.7)), url(${exclusiveOffersImage})` }}>
                <div className='exclusiveOffersImageTextContainer'>
                      <p className='exclusiveOffersImageText'>
                        {np?(
                          `प्राइम बैंकको ${value.title_nepali.length===0?(
                            value.title
                          ):(
                            value.title_nepali
                          )} सेवामा धेरै रोमाञ्चक अफरहरू र ट्रेन्डिङ डिलहरूमा पहुँच प्राप्त गर्नुहोस्`
                        ):(
                          "Gain access to many exciting offers & trending deals on various Prime Bank’s "+value.title+"."
                        )}
                      </p>
                      <Link to="/offers" className='exclusiveOffersImageTextButton' state={{ from: value.slug, title: value.title, title_nepali: value.title_nepali }}>
                        <p className='exclusiveOffersImageTextButtonText'>
                          {np?(
                            `${value.title_nepali.length===0?(
                              `View All ${value.title} Offers`
                            ):(value.title_nepali)} अफरहरू हेर्नुहोस्`
                          ):(
                            `View All ${value.title} Offers`
                          )}
                        </p>
                        <img className='exclusiveOffersImageTextButtonIcon' src={chevronRight} alt="chevronRight"/>
                      </Link>
                </div> 
            </div>

          <div className='exclusiveOfferResultContainerWrapper'> 
            {value.offers.length > 0 ? (value.offers.map((offer, index) => 
            <Link key={index} to={`/offers-details/${offer.slug}`}>
              <div className='exclusiveOfferResultContainer'>
              <LazyLoadImage effect="blur" className='exclusiveOfferResultImage' src={offer.brand_image} alt={offer.slug} />
              <h4 className='exclusiveOfferResultTitle'>
                {np?(
                  offer.title_nepali.length===0?(offer.title):(offer.title_nepali)
                ):(
                  offer.title
                )}
              </h4>
              <Link to={`/offers-details/${offer.slug}`} className='exclusiveOfferResultButtonContainer'>
                <p className='exclusiveOfferResultButtonText'>
                {np?("थप जान्नुहोस्"):("Know More")}
                </p>
                <img className='exclusiveOfferResultButtonIcon' src={knowIconPrimary} alt="knowIcon" />
              </Link>
            </div>
            </Link>
            )) : (
              <div className='exclusiveOfferResultContainer'>
                 <p className="exclusiveOfferResultNoOfferText">{np?(
                  "माफ गर्नुहोस्, तर हामीसँग अहिले तपाईंको लागि कुनै योजना उपलब्ध छैन।"
                 ):(
                  "Sorry, but we have no available offers for you at the moment."
                 )}</p>
              </div>

            )
            }

          </div>
          </div>
        </TabPanel>
            )}

        </Tabs>
        </motion.div>
    </div>
      ) : (
        <div className='bgColor offersHide'>
        {/* tabs */}
        <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={Animation}
        >
        <Tabs 
        className='offersContainer'>
            <div className='offersHeadContainer'>
                <h3 className='offersHead'>{np? 'विशेष प्रस्तावहरू' : 'Exclusive Offers'}</h3>
                <TabList className='offersTabButtonsContainer' defaultIndex={0}>
                  {values?.map((value) => 
                      <Tab key={value.order_no} className='offersTabButton'>
                        {np?(
                          value.title_nepali.length === 0? (
                            value.title
                          ) : (
                            value.title_nepali
                          )
                        ):(
                          value.title
                        )}
                      </Tab>
                  )}

                </TabList>
            </div>

            {/* tabPanel */}
          {values?.map((value) => 
          <TabPanel key={value.order_no}>
          <div className='exclusiveOffersFlexContainer'>
            <div className='exclusiveOffersImageContainer' style={{ background: `linear-gradient(rgb(169, 58, 124,0.7), rgb(169, 58, 124,0.7)), url(${exclusiveOffersImage})` }}>
                <div className='exclusiveOffersImageTextContainer'>
                      <p className='exclusiveOffersImageText'>
                      {np?(
                          `प्राइम बैंकको ${value.title_nepali.length===0?(
                            value.title
                          ):(
                            value.title_nepali
                          )} सेवामा धेरै रोमाञ्चक अफरहरू र ट्रेन्डिङ डिलहरूमा पहुँच प्राप्त गर्नुहोस्`
                        ):(
                          "Gain access to many exciting offers & trending deals on various Prime Bank’s "+value.title+"."
                      )}
                      </p>
                      <Link to="/offers" className='exclusiveOffersImageTextButton' state={{ from: value.slug, title: value.title, title_nepali: value.title_nepali }}>
                        <p className='exclusiveOffersImageTextButtonText'>
                        {np?(
                            `${value.title_nepali.length===0?(
                              value.title
                            ):(value.title_nepali)} अफरहरू हेर्नुहोस्`
                          ):(
                            `View All ${value.title} Offers`
                        )}
                        </p>
                        <img className='exclusiveOffersImageTextButtonIcon' src={chevronRight} alt="chevronRight"/>
                      </Link>
                </div> 
            </div>

          <div className='exclusiveOfferResultContainerWrapper'> 
            {value.offers.length > 0 ? (value.offers.slice(0, 2).map((offer, index) => 
            <Link key={index} to={`/offers-details/${offer.slug}`}>
              <div className='exclusiveOfferResultContainer'>
              <img className='exclusiveOfferResultImage' src={offer.brand_image} alt={offer.slug} />
              <h4 className='exclusiveOfferResultTitle'>
              {np?(
                  offer.title_nepali.length===0?(offer.title):(offer.title_nepali)
                ):(
                  offer.title
              )}
              </h4>
              <Link to={`/offers-details/${offer.slug}`} className='exclusiveOfferResultButtonContainer'>
                <p className='exclusiveOfferResultButtonText'>
                {np?("थप जान्नुहोस्"):("Know More")}
                </p>
                <img className='exclusiveOfferResultButtonIcon' src={knowIconPrimary} alt="knowIcon" />
              </Link>
            </div>
            </Link>
            )) : (
              <div className='exclusiveOfferResultContainer'>
                 <p className="exclusiveOfferResultNoOfferText">
                 {np?(
                  "माफ गर्नुहोस्, तर हामीसँग अहिले तपाईंको लागि कुनै योजना उपलब्ध छैन।"
                 ):(
                  "Sorry, but we have no available offers for you at the moment."
                 )}
                 </p>
              </div>

            )
            }

          </div>
          </div>
        </TabPanel>
            )}

        </Tabs>
        </motion.div>
    </div>
      )}
    </>
    )}
    </>

  )
}

export default Offers;