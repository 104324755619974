import React, { useContext } from "react";
import { LangContext } from "../../context/LangContext";
import { Link } from "react-router-dom";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Animation from "../../utils/Animation";
import { AiOutlineMail, AiOutlineMobile, AiOutlinePhone } from "react-icons/ai";
import { BsTelephoneOutbound } from "react-icons/bs";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "./keycontact.css";
const KeyContact = ({ grievance, information, compliance, secretary }) => {
  const lang = useContext(LangContext);
  const np = lang.state.np;
  return (
    <>
      <div className="KayContact">
        <h2>Key Contacts</h2>
        <div className="KeyBoxxx">
          <div className="left">
            <h2>For queries, Contact Us</h2>
            <p>
              <Link to="get-in-touch">Contect Us</Link>
            </p>
            <p>
              <Link to="grievance-form">Lodge a Complaint (गुनासो दर्ता)</Link>{" "}
            </p>
          </div>
          <div className="right">
            <div className="landingAccordionOfficerContainer">
              <LazyLoadImage
                effect="blur"
                className="landingAccordionImage"
                src={information?.featured_image}
                alt={information?.slug}
              />
              <div className="landingAccordionDescContainer">
                <p className="landingAccordionName">
                  {np
                    ? information?.name_nepali?.length === 0
                      ? information?.name
                      : information?.name_nepali
                    : information?.name}
                </p>
                <p className="landingAccordionEmailFlex">
                  {np ? "प्रमुख सूचना अधिकारी" : "Chief Information Officer"}
                </p>
                <div className="landingAccordionEmailFlex">
                  <AiOutlineMail
                    style={{ fontSize: "18px", color: "#663366" }}
                  />
                  <p className="landingAccordionEmail">{information?.email}</p>
                </div>
                <div className="landingAccordionEmailFlex">
                  <AiOutlinePhone
                    style={{ fontSize: "18px", color: "#663366" }}
                  />
                  <p className="landingAccordionPhone">
                    {information?.contact}
                  </p>
                </div>
              </div>
            </div>
            <div className="landingAccordionOfficerContainer">
              <LazyLoadImage
                effect="blur"
                className="landingAccordionImage"
                src={grievance?.featured_image}
                alt={grievance?.slug}
              />
              <div className="landingAccordionDescContainer">
                <p className="landingAccordionName">
                  {np
                    ? grievance?.name_nepali?.length === 0
                      ? grievance?.name
                      : grievance?.name_nepali
                    : grievance?.name}
                </p>
                <p className="landingAccordionEmailFlex">
                  {np
                    ? grievance?.sub_designation_nepali?.length === 0
                      ? grievance?.sub_designation
                      : grievance?.sub_designation_nepali
                    : grievance?.sub_designation}
                </p>
                <p className="landingAccordionEmailFlex">
                  {np ? "गुनासो अधिकारी" : "Grievance Officer"}
                </p>

                <div className="landingAccordionEmailFlex">
                  <AiOutlineMail
                    style={{ fontSize: "18px", color: "#663366" }}
                  />
                  <p className="landingAccordionEmail">{grievance?.email}</p>
                </div>

                <div className="landingAccordionEmailFlex">
                  <AiOutlineMobile
                    style={{ fontSize: "18px", color: "#663366" }}
                  />
                  <p className="landingAccordionMobile">{grievance?.contact}</p>
                </div>

                <div className="landingAccordionEmailFlex">
                  <AiOutlinePhone
                    style={{ fontSize: "18px", color: "#663366" }}
                  />
                  <p className="landingAccordionPhone">01-4423215 ext: 2165</p>
                </div>
              </div>
            </div>
            <div className="MoreBTNsssss">
              <button>
                <Link to="key-contact">More information</Link>
              </button>
              <div className="Numbersss">
                <p>
                  {/* <AiOutlinePhone style={{ fontSize: "18px", color: "#fff" }} />{" "} */}
                  <span> Toll Free Telephone No :</span>
                  <span> 18105000150</span>
                </p>
                <p>
                  <AiOutlinePhone style={{ fontSize: "18px", color: "#fff" }} />{" "}
                  <span> Phone :</span>
                  <span> +977 01-4523254,01-4523432/3 & 01- 4523436</span>
                </p>
                <p>
                  <AiOutlineMail style={{ fontSize: "18px", color: "#fff" }} />
                  <span> Email :</span>
                  <span>info@pcbl.com.np</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default KeyContact;
