import React, { useState, useEffect, useRef } from "react";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Animation from '../../utils/Animation';
import { Link } from "react-router-dom";

import{ IoIosArrowDropleftCircle, IoIosArrowDroprightCircle } from 'react-icons/io'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";    
import "swiper/css/navigation";
import "swiper/css/scrollbar";

// import required modules
import { Navigation, Scrollbar, A11y, Controller } from "swiper";

import accountIcon from '../../assets/img/accountIcon.png'
import cardsIcon from '../../assets/img/cardsIcon.png'
import loansIcon from '../../assets/img/loansIcon.png'
import know from '../../assets/img/know1.png'
import nextIcon from '../../assets/img/nextIcon.png'
import prevIcon from '../../assets/img/prevIcon.png'

// import './servicetabs.css'

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <img 
        className={className} 
        src={nextIcon} 
        alt="next" 
        style={{ ...style, height: "30px", width: "auto", }} 
        onClick={onClick} 
      />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <img 
        className={className} 
        src={prevIcon} 
        alt="next" 
        style={{ ...style, height: "30px", width: "auto", }} 
        onClick={onClick} 
      />
    );
}
  
const ServiceApi = ({ values }) => {
    const controls = useAnimation();
    const [ref, inView] = useInView();
  
    useEffect(() => {
      if(inView) {
        controls.start("visible");
      }
    }, [controls, inView]);

    // parent slick slider
  const settings = {
    className: "slider variable-width",
    variableWidth: true,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const CustomTab = ({onClick, children}) => {
    return <div onClick={onClick}>{children}</div>
  };
  CustomTab.tabsRole = 'Tab';

  const [tabIndex, setTabIndex] = useState(0);

  const [currentTab, setCurrentTab] = useState('0');

  useEffect(() => {
      setCurrentTab(tabIndex)
  }, [tabIndex])

  const activeTab = (index) => `${currentTab === index && "active-service-tab"}`;

  const [swiper, setSwiper] = useState();
  const prevRef = useRef();
  const nextRef = useRef();

  useEffect(() => {
    if (swiper) {
      swiper.params.navigation.prevEl = prevRef.current;
      swiper.params.navigation.nextEl = nextRef.current;
      swiper.navigation.init();
      swiper.navigation.update();
    }
  }, [swiper]);

  return (
    <div className='bgColor'>
    <motion.div 
    ref={ref}
    animate={controls}
    initial="hidden"
    variants={Animation}
    className='servicesContainer'>
      <div className='servicesHeaderContainer'>
          <h2 className='servicecsHeader'>Services</h2>
          <p className='servicecsSubHeader'>Check out our diversed services for different types of accounts & loans</p>
      </div>

      {/* tabs */}
        <Tabs className='servicesTabsContainer' defaultIndex={1}>

          <TabList className='servicesTabsButtonContainer'>
             {values?.map((value) => 
             <Tab key={value.rank} className='servicesTabButton'>{value.title}</Tab>
             )}
          </TabList>

        {/* accounts Tab Panel */}
        {values.map((item) => 
        <TabPanel key={item.rank}>
        <div className='tabButtonDesc'>
            <img className='tabButtonDescImg' src={accountIcon} alt={item.slug} />
            <div className='tabButtonDescFlex'>
            <h3 className='tabButtonDescHead'>{item.title}</h3>
            <p className='tabButtonDescPara'>{item.description}</p>
            </div>
        </div>

        <Tabs 
          selectedIndex={tabIndex}
          onSelect={index => setTabIndex(index)}
        >   
                {item.subcategory.map((subItem) => 
                    <TabList  className='subTabsHeadContainer'>
                        <CustomTab 
                        onClick={()=> setTabIndex(0)} className="subTabsHead">
                                <h4 className={`subTabsHeadTitle ${activeTab(0)}`}>Savings Accounts</h4>   
                        </CustomTab>

                            <CustomTab 
                            onClick={()=> setTabIndex(1)}
                            className="subTabsHead">
                                <h4 className={`subTabsHeadTitle ${activeTab(1)}`}>Current Accounts</h4>
                            </CustomTab>

                            <CustomTab 
                            onClick={()=> setTabIndex(2)}   
                            className="subTabsHead">
                                <h4 className={`subTabsHeadTitle ${activeTab(2)}`}>Fixed Deposit</h4>
                            </CustomTab>

                            <CustomTab 
                            onClick={()=> setTabIndex(3)}
                            className="subTabsHead">
                                <h4 className={`subTabsHeadTitle ${activeTab(3)}`}>Branch Products</h4>
                            </CustomTab>

                            <CustomTab 
                            onClick={()=> setTabIndex(4)}
                            className="subTabsHead">
                                <h4 className={`subTabsHeadTitle ${activeTab(4)}`}>Recurring Deposit</h4>
                            </CustomTab>
                    </TabList>
     
                )}


            {/* saving account */}
                <TabPanel>
                    <div className='serviceCardContainer'>
                        <Swiper
                            modules={[Navigation, Scrollbar, A11y, Controller]}
                            className="external-buttons serviceCardSwiperContainer"
                            slidesPerView={1}
                            spaceBetween={35}
                            navigation={{
                                prevEl: prevRef?.current,
                                nextEl: nextRef?.current
                            }}
                            breakpoints={{
                                640: {
                                  slidesPerView: 1,
                                  spaceBetween: 20,
                                },
                                768: {
                                  slidesPerView: 2,
                                  spaceBetween: 40,
                                },
                                1024: {
                                  slidesPerView: 3,
                                  spaceBetween: 50,
                                },
                            }}
                            updateOnWindowResize
                            observer
                            observeParents
                            onSwiper={setSwiper}
                        >
                            <SwiperSlide className='serviceCard'>
                                    <h5 className='cardHead'>Annapurna Saving</h5>
                                    <p className='cardDesc'>On demand statement, free cheque book, unlimited withdrawal/ deposit, free ABBS facility.</p>
                                    <div className='serviceCardButtonContainer'>

                                    <div className='serviceKnowContainer'>
                                    <a className='cardKnowButton'>Know More</a>
                                    <img
                                        className='serviceKnowImg'
                                        src={know}
                                    />
                                    </div>
                                    </div>
                            </SwiperSlide>

                            <SwiperSlide className='serviceCard'>
                                <h5 className='cardHead'>Prime Bhidyut Saving Account</h5>
                                <p className='cardDesc'>On demand statement, free cheque book, unlimited withdrawal/ deposit, free ABBS facility. </p>
                                <div className='serviceCardButtonContainer'>

                                    <Link to="/service-details" className='serviceKnowContainer'>
                                    <a className='cardKnowButton'>Know More</a>
                                    <img
                                        className='serviceKnowImg'
                                        src={know}
                                    />
                                    </Link>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide className='serviceCard'>
                                <h5 className='cardHead'>Balkumari Saving Account</h5>
                                <p className='cardDesc'>On demand statement, free cheque book, unlimited withdrawal/ deposit, free ABBS facility. </p>
                                <div className='serviceCardButtonContainer'>

                                    <Link to="/service-details" className='serviceKnowContainer'>
                                    <a className='cardKnowButton'>Know More</a>
                                    <img
                                        className='serviceKnowImg'
                                        src={know}
                                    />
                                    </Link>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide className='serviceCard'>
                                <h5 className='cardHead'>Bindhabasini Saving</h5>
                                <p className='cardDesc'>On demand statement, free cheque book, unlimited withdrawal/ deposit, free ABBS facility. </p>
                                <div className='serviceCardButtonContainer'>

                                    <Link to="/service-details" className='serviceKnowContainer'>
                                    <a className='cardKnowButton'>Know More</a>
                                    <img
                                        className='serviceKnowImg'
                                        src={know}
                                    />
                                    </Link>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide className='serviceCard'>
                                <h5 className='cardHead'>Bramhayani Saving</h5>
                                <p className='cardDesc'>On demand statement, free cheque book, unlimited withdrawal/ deposit, free ABBS facility. </p>
                                <div className='serviceCardButtonContainer'>

                                    <Link to="/service-details" className='serviceKnowContainer'>
                                    <a className='cardKnowButton'>Know More</a>
                                    <img
                                        className='serviceKnowImg'
                                        src={know}
                                    />
                                    </Link>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide className='serviceCard'>
                                <h5 className='cardHead'>Buddha Subba Saving</h5>
                                <p className='cardDesc'>On demand statement, free cheque book, unlimited withdrawal/ deposit, free ABBS facility. </p>
                                <div className='serviceCardButtonContainer'>

                                    <Link to="/service-details" className='serviceKnowContainer'>
                                    <a className='cardKnowButton'>Know More</a>
                                    <img
                                        className='serviceKnowImg'
                                        src={know}
                                    />
                                    </Link>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide className='serviceCard'>
                                <h5 className='cardHead'>Fixed Deposit</h5>
                                <p className='cardDesc'>On demand statement, free cheque book, unlimited withdrawal/ deposit, free ABBS facility. </p>
                                <div className='serviceCardButtonContainer'>

                                    <Link to="/service-details" className='serviceKnowContainer'>
                                    <a className='cardKnowButton'>Know More</a>
                                    <img
                                        className='serviceKnowImg'
                                        src={know}
                                    />
                                    </Link>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide className='serviceCard'>
                                <h5 className='cardHead'>Prime Gold Saving</h5>
                                <p className='cardDesc'>On demand statement, free cheque book, unlimited withdrawal/ deposit, free ABBS facility. </p>
                                <div className='serviceCardButtonContainer'>

                                    <Link to="/service-details" className='serviceKnowContainer'>
                                    <a className='cardKnowButton'>Know More</a>
                                    <img
                                        className='serviceKnowImg'
                                        src={know}
                                    />
                                    </Link>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide className='serviceCard'>
                                <h5 className='cardHead'>Kamalamai Saving</h5>
                                <p className='cardDesc'>On demand statement, free cheque book, unlimited withdrawal/ deposit, free ABBS facility. </p>
                                <div className='serviceCardButtonContainer'>

                                    <Link to="/service-details" className='serviceKnowContainer'>
                                    <a className='cardKnowButton'>Know More</a>
                                    <img
                                        className='serviceKnowImg'
                                        src={know}
                                    />
                                    </Link>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide className='serviceCard'>
                                <h5 className='cardHead'>Normal Saving</h5>
                                <p className='cardDesc'>On demand statement, free cheque book, unlimited withdrawal/ deposit, free ABBS facility. </p>
                                <div className='serviceCardButtonContainer'>

                                    <Link to="/service-details" className='serviceKnowContainer'>
                                    <a className='cardKnowButton'>Know More</a>
                                    <img
                                        className='serviceKnowImg'
                                        src={know}
                                    />
                                    </Link>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide className='serviceCard'>
                                <h5 className='cardHead'>Prime Bal Bachat Khata</h5>
                                <p className='cardDesc'>On demand statement, free cheque book, unlimited withdrawal/ deposit, free ABBS facility. </p>
                                <div className='serviceCardButtonContainer'>

                                    <Link to="/service-details" className='serviceKnowContainer'>
                                    <a className='cardKnowButton'>Know More</a>
                                    <img
                                        className='serviceKnowImg'
                                        src={know}
                                    />
                                    </Link>
                                </div>
                            </SwiperSlide>

                        </Swiper>
                        </div>

                    <div className='servicesSliderContainer'>
                    <div ref={prevRef}>
                            <IoIosArrowDropleftCircle className="serviceSlideIcon swiper-button" />
                        </div>
                        <div ref={nextRef}>
                             <IoIosArrowDroprightCircle className="serviceSlideIcon swiper-button" />
                        </div>
                    </div>   
                </TabPanel>  

            {/* current account */}
            <TabPanel>
                    <div className='serviceCardContainer'>
                        <Swiper
                            modules={[Navigation, Scrollbar, A11y, Controller]}
                            className="external-buttons serviceCardSwiperContainer"
                            slidesPerView={1}
                            spaceBetween={35}
                            navigation={{
                                prevEl: prevRef?.current,
                                nextEl: nextRef?.current
                            }}
                            breakpoints={{
                                640: {
                                  slidesPerView: 1,
                                  spaceBetween: 20,
                                },
                                768: {
                                  slidesPerView: 2,
                                  spaceBetween: 40,
                                },
                                1024: {
                                  slidesPerView: 3,
                                  spaceBetween: 50,
                                },
                            
                            }}
                            updateOnWindowResize
                            observer
                            observeParents
                            onSwiper={setSwiper}
                        >
                            <SwiperSlide className='serviceCard'>
                                    <h5 className='cardHead'>Prime Bidhyut Saving AccountC </h5>
                                    <p className='cardDesc'>On demand statement, free cheque book, unlimited withdrawal/ deposit, free ABBS facility.</p>
                                    <div className='serviceCardButtonContainer'>

                                    <div className='serviceKnowContainer'>
                                    <a className='cardKnowButton'>Know More</a>
                                    <img
                                        className='serviceKnowImg'
                                        src={know}
                                    />
                                    </div>
                                    </div>
                            </SwiperSlide>

                            <SwiperSlide className='serviceCard'>
                                <h5 className='cardHead'>Prime Bal Current Khata </h5>
                                <p className='cardDesc'>On demand statement, free cheque book, unlimited withdrawal/ deposit, free ABBS facility. </p>
                                <div className='serviceCardButtonContainer'>

                                    <Link to="/service-details" className='serviceKnowContainer'>
                                    <a className='cardKnowButton'>Know More</a>
                                    <img
                                        className='serviceKnowImg'
                                        src={know}
                                    />
                                    </Link>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide className='serviceCard'>
                                <h5 className='cardHead'>Prime Nari Current Khata</h5>
                                <p className='cardDesc'>On demand statement, free cheque book, unlimited withdrawal/ deposit, free ABBS facility. </p>
                                <div className='serviceCardButtonContainer'>

                                    <Link to="/service-details" className='serviceKnowContainer'>
                                    <a className='cardKnowButton'>Know More</a>
                                    <img
                                        className='serviceKnowImg'
                                        src={know}
                                    />
                                    </Link>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide className='serviceCard'>
                                <h5 className='cardHead'>Prime Locker Saving</h5>
                                <p className='cardDesc'>On demand statement, free cheque book, unlimited withdrawal/ deposit, free ABBS facility. </p>
                                <div className='serviceCardButtonContainer'>

                                    <Link to="/service-details" className='serviceKnowContainer'>
                                    <a className='cardKnowButton'>Know More</a>
                                    <img
                                        className='serviceKnowImg'
                                        src={know}
                                    />
                                    </Link>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide className='serviceCard'>
                                <h5 className='cardHead'>Prime Premium Senior Citizen Account</h5>
                                <p className='cardDesc'>On demand statement, free cheque book, unlimited withdrawal/ deposit, free ABBS facility. </p>
                                <div className='serviceCardButtonContainer'>

                                    <Link to="/service-details" className='serviceKnowContainer'>
                                    <a className='cardKnowButton'>Know More</a>
                                    <img
                                        className='serviceKnowImg'
                                        src={know}
                                    />
                                    </Link>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                        </div>

                    <div className='servicesSliderContainer'>
                    <div ref={prevRef}>
                            <IoIosArrowDropleftCircle className="serviceSlideIcon swiper-button" />
                        </div>
                        <div ref={nextRef}>
                             <IoIosArrowDroprightCircle className="serviceSlideIcon swiper-button" />
                        </div>
                    </div>   
            </TabPanel>  

            {/* fixed account */}
            <TabPanel>
                    <div className='serviceCardContainer'>
                        <Swiper
                            modules={[Navigation, Scrollbar, A11y, Controller]}
                            className="external-buttons serviceCardSwiperContainer"
                            slidesPerView={1}
                            spaceBetween={35}
                            navigation={{
                                prevEl: prevRef?.current,
                                nextEl: nextRef?.current
                            }}
                            breakpoints={{
                                640: {
                                  slidesPerView: 1,
                                  spaceBetween: 20,
                                },
                                768: {
                                  slidesPerView: 2,
                                  spaceBetween: 40,
                                },
                                1024: {
                                  slidesPerView: 3,
                                  spaceBetween: 50,
                                },
                            }}
                            updateOnWindowResize
                            observer
                            observeParents
                            onSwiper={setSwiper}
                        >
                            <SwiperSlide className='serviceCard'>
                                    <h5 className='cardHead'>Prime Dhanabridhi Muddati </h5>
                                    <p className='cardDesc'>On demand statement, free cheque book, unlimited withdrawal/ deposit, free ABBS facility.</p>
                                    <div className='serviceCardButtonContainer'>

                                    <div className='serviceKnowContainer'>
                                    <a className='cardKnowButton'>Know More</a>
                                    <img
                                        className='serviceKnowImg'
                                        src={know}
                                    />
                                    </div>
                                    </div>
                            </SwiperSlide>

                            <SwiperSlide className='serviceCard'>
                                <h5 className='cardHead'>Prime Dhanabridhi Remit Muddati Khata </h5>
                                <p className='cardDesc'>On demand statement, free cheque book, unlimited withdrawal/ deposit, free ABBS facility. </p>
                                <div className='serviceCardButtonContainer'>

                                    <Link to="/service-details" className='serviceKnowContainer'>
                                    <a className='cardKnowButton'>Know More</a>
                                    <img
                                        className='serviceKnowImg'
                                        src={know}
                                    />
                                    </Link>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide className='serviceCard'>
                                <h5 className='cardHead'>Prime Dhanabridhi Sansthagat Muddati Khata</h5>
                                <p className='cardDesc'>On demand statement, free cheque book, unlimited withdrawal/ deposit, free ABBS facility. </p>
                                <div className='serviceCardButtonContainer'>

                                    <Link to="/service-details" className='serviceKnowContainer'>
                                    <a className='cardKnowButton'>Know More</a>
                                    <img
                                        className='serviceKnowImg'
                                        src={know}
                                    />
                                    </Link>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                        </div>

                    <div className='servicesSliderContainer'>
                    <div ref={prevRef}>
                            <IoIosArrowDropleftCircle className="serviceSlideIcon swiper-button" />
                        </div>
                        <div ref={nextRef}>
                             <IoIosArrowDroprightCircle className="serviceSlideIcon swiper-button" />
                        </div>
                    </div>   
            </TabPanel>  

            {/* Branch account */}
            <TabPanel>
                    <div className='serviceCardContainer'>
                        <Swiper
                            modules={[Navigation, Scrollbar, A11y, Controller]}
                            className="external-buttons serviceCardSwiperContainer"
                            slidesPerView={1}
                            spaceBetween={35}
                            navigation={{
                                prevEl: prevRef?.current,
                                nextEl: nextRef?.current
                            }}
                            breakpoints={{
                                640: {
                                  slidesPerView: 1,
                                  spaceBetween: 20,
                                },
                                768: {
                                  slidesPerView: 2,
                                  spaceBetween: 40,
                                },
                                1024: {
                                  slidesPerView: 3,
                                  spaceBetween: 50,
                                },
                            }}
                            updateOnWindowResize
                            observer
                            observeParents
                            onSwiper={setSwiper}
                        >
                            <SwiperSlide className='serviceCard'>
                                    <h5 className='cardHead'>Siddhi Ganesh Saving Account</h5>
                                    <p className='cardDesc'>On demand statement, free cheque book, unlimited withdrawal/ deposit, free ABBS facility.</p>
                                    <div className='serviceCardButtonContainer'>

                                    <div className='serviceKnowContainer'>
                                    <a className='cardKnowButton'>Know More</a>
                                    <img
                                        className='serviceKnowImg'
                                        src={know}
                                    />
                                    </div>
                                    </div>
                            </SwiperSlide>

                            <SwiperSlide className='serviceCard'>
                                <h5 className='cardHead'>Balkumari Saving Account</h5>
                                <p className='cardDesc'>On demand statement, free cheque book, unlimited withdrawal/ deposit, free ABBS facility. </p>
                                <div className='serviceCardButtonContainer'>

                                    <Link to="/service-details" className='serviceKnowContainer'>
                                    <a className='cardKnowButton'>Know More</a>
                                    <img
                                        className='serviceKnowImg'
                                        src={know}
                                    />
                                    </Link>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide className='serviceCard'>
                                <h5 className='cardHead'>Buddha Subba Saving Account</h5>
                                <p className='cardDesc'>On demand statement, free cheque book, unlimited withdrawal/ deposit, free ABBS facility. </p>
                                <div className='serviceCardButtonContainer'>

                                    <Link to="/service-details" className='serviceKnowContainer'>
                                    <a className='cardKnowButton'>Know More</a>
                                    <img
                                        className='serviceKnowImg'
                                        src={know}
                                    />
                                    </Link>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide className='serviceCard'>
                                <h5 className='cardHead'>Bindhabasini Saving</h5>
                                <p className='cardDesc'>On demand statement, free cheque book, unlimited withdrawal/ deposit, free ABBS facility. </p>
                                <div className='serviceCardButtonContainer'>

                                    <Link to="/service-details" className='serviceKnowContainer'>
                                    <a className='cardKnowButton'>Know More</a>
                                    <img
                                        className='serviceKnowImg'
                                        src={know}
                                    />
                                    </Link>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide className='serviceCard'>
                                <h5 className='cardHead'>Narayani Saving</h5>
                                <p className='cardDesc'>On demand statement, free cheque book, unlimited withdrawal/ deposit, free ABBS facility. </p>
                                <div className='serviceCardButtonContainer'>

                                    <Link to="/service-details" className='serviceKnowContainer'>
                                    <a className='cardKnowButton'>Know More</a>
                                    <img
                                        className='serviceKnowImg'
                                        src={know}
                                    />
                                    </Link>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide className='serviceCard'>
                                <h5 className='cardHead'>Kamalamai Saving</h5>
                                <p className='cardDesc'>On demand statement, free cheque book, unlimited withdrawal/ deposit, free ABBS facility. </p>
                                <div className='serviceCardButtonContainer'>

                                    <Link to="/service-details" className='serviceKnowContainer'>
                                    <a className='cardKnowButton'>Know More</a>
                                    <img
                                        className='serviceKnowImg'
                                        src={know}
                                    />
                                    </Link>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide className='serviceCard'>
                                <h5 className='cardHead'>Tara Saving</h5>
                                <p className='cardDesc'>On demand statement, free cheque book, unlimited withdrawal/ deposit, free ABBS facility. </p>
                                <div className='serviceCardButtonContainer'>

                                    <Link to="/service-details" className='serviceKnowContainer'>
                                    <a className='cardKnowButton'>Know More</a>
                                    <img
                                        className='serviceKnowImg'
                                        src={know}
                                    />
                                    </Link>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide className='serviceCard'>
                                <h5 className='cardHead'>Manakamana Saving</h5>
                                <p className='cardDesc'>On demand statement, free cheque book, unlimited withdrawal/ deposit, free ABBS facility. </p>
                                <div className='serviceCardButtonContainer'>

                                    <Link to="/service-details" className='serviceKnowContainer'>
                                    <a className='cardKnowButton'>Know More</a>
                                    <img
                                        className='serviceKnowImg'
                                        src={know}
                                    />
                                    </Link>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                        </div>

                    <div className='servicesSliderContainer'>
                    <div ref={prevRef}>
                            <IoIosArrowDropleftCircle className="serviceSlideIcon swiper-button" />
                        </div>
                        <div ref={nextRef}>
                             <IoIosArrowDroprightCircle className="serviceSlideIcon swiper-button" />
                        </div>
                    </div>   
            </TabPanel>
            
                            {/* recurring account */}
                            <TabPanel>
                    <div className='serviceCardContainer'>
                        <Swiper
                            modules={[Navigation, Scrollbar, A11y, Controller]}
                            className="external-buttons serviceCardSwiperContainer"
                            slidesPerView={1}
                            spaceBetween={35}
                            navigation={{
                                prevEl: prevRef?.current,
                                nextEl: nextRef?.current
                            }}
                            breakpoints={{
                                640: {
                                  slidesPerView: 1,
                                  spaceBetween: 20,
                                },
                                768: {
                                  slidesPerView: 2,
                                  spaceBetween: 40,
                                },
                                1024: {
                                  slidesPerView: 3,
                                  spaceBetween: 50,
                                },
                            }}
                            updateOnWindowResize
                            observer
                            observeParents
                            onSwiper={setSwiper}
                        >
                            <SwiperSlide className='serviceCard'>
                                    <h5 className='cardHead'>Prime Recurring Deposit Account</h5>
                                    <p className='cardDesc'>On demand statement, free cheque book, unlimited withdrawal/ deposit, free ABBS facility.</p>
                                    <div className='serviceCardButtonContainer'>

                                    <div className='serviceKnowContainer'>
                                    <a className='cardKnowButton'>Know More</a>
                                    <img
                                        className='serviceKnowImg'
                                        src={know}
                                    />
                                    </div>
                                    </div>
                            </SwiperSlide>

                            <SwiperSlide className='serviceCard'>
                                <h5 className='cardHead'>Prime Recurring Remit Deposit Account</h5>
                                <p className='cardDesc'>On demand statement, free cheque book, unlimited withdrawal/ deposit, free ABBS facility. </p>
                                <div className='serviceCardButtonContainer'>

                                    <Link to="/service-details" className='serviceKnowContainer'>
                                    <a className='cardKnowButton'>Know More</a>
                                    <img
                                        className='serviceKnowImg'
                                        src={know}
                                    />
                                    </Link>
                                </div>
                            </SwiperSlide>

                        </Swiper>
                        </div>

                    <div className='servicesSliderContainer'>
                    <div ref={prevRef}>
                            <IoIosArrowDropleftCircle className="serviceSlideIcon swiper-button" />
                        </div>
                        <div ref={nextRef}>
                             <IoIosArrowDroprightCircle className="serviceSlideIcon swiper-button" />
                        </div>
                    </div>   
            </TabPanel>  
        </Tabs>
         </TabPanel>
        )}


        </Tabs>
    </motion.div>
    </div>
  )
}

export default ServiceApi