import * as React from 'react';
import { LangContext } from '../../context/LangContext';
import {Link} from 'react-router-dom';

import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Animation from '../../utils/Animation';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";    
import "swiper/css/navigation";
import "swiper/css/scrollbar";
// import required modules
import { Autoplay, Navigation, Scrollbar, A11y, Controller } from "swiper";
import './rates.css';

const Interest = ({ values }) => {
    const lang = React.useContext(LangContext);
    const np = lang.state.np;
    const controls = useAnimation();
    const [ref, inView] = useInView();
  
    React.useEffect(() => {
      if(inView) {
        controls.start("visible");
      }
    }, [controls, inView]);

    return (
        <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={Animation}
        className="IntRatesBox">
            <Box className="IntTitle" component="div">
                <div>
                    <h2 className='interestHeader'>
                            {np? 'कर्जा सेवाहरू' : 'Loan Services'}
                    </h2>
                    <p className='interestSubHeader'>
                        {np? 'विभिन्न प्रकारका कर्जाहरूको लागि हाम्रो प्रतिस्पर्धी दरहरू र सेवा शुल्कहरू हेर्नुहोस्।' : 'Check out our competitive facilities for different types of loans.'}
                    </p>
                </div>
            </Box>
            <Box className='IntItems' component='div'>
            <Swiper
                modules={[Navigation, Scrollbar, A11y, Controller, Autoplay]}
                className="serviceCardSwiperContainer"
                slidesPerView={1}
                loop={true}
                grabCursor={true}
                autoplay={{
                    delay: 4000,
                    disableOnInteraction: false,
                }}
                speed={2000}
                // spaceBetween={5}
                // navigation={{
                //     prevEl: prevRef?.current,
                //     nextEl: nextRef?.current
                // }}
                navigation={false}
                breakpoints={{
                    640: {
                      slidesPerView: 1,
                    //   spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 2,
                    //   spaceBetween: 40,
                    },
                    1024: {
                      slidesPerView: 4,
                    //   spaceBetween: 50,
                    },
                }}
                updateOnWindowResize
                observer
                observeParents
                // onSwiper={setSwiper}
            >
                {values?.map((value) => 
                    <SwiperSlide key={value.id} className='IntBox' component='div'>
                    <Box className='IMGBox' component='div'>
                        <Box className='img' component='div'>
                            <img src={value.DocPath} alt={value.Title} />
                        </Box>
                    </Box>
                    <Box className='ItemsTitle' component='div'>
                        <Typography variant='h4' style={{textAlign: 'left'}}>
                            {/* {value.Title} */}
                            {np?(
                                value.TitleNepali.length === 0? (
                                    value.Title
                                ) : (
                                    value.TitleNepali
                                )
                            ) : (
                                    value.Title
                            )}
                        </Typography>
                        {/* <Typography variant='span' style={{ textAlign: 'left' }}>
                            {value.rate}% p.a
                        </Typography> */}
                    </Box>
                    </SwiperSlide>
                )}
            </Swiper>

            </Box>
            <Link to="/interest-rates">
                <Box className='MoreBTN' component='div'>
                    <Typography component='span'>
                        {np?(
                            "थप जान्नुहोस्"
                        ):(
                            "Know More"
                        )}
                    </Typography>
                </Box>
            </Link>

        </motion.div>
    )
  }
  
  export default Interest