import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://primebank.com.np/pr1me4dm1n/api",
    // baseUrl: "https://nyatapol.biz/prime_dash/api",
  }),
  tagTypes: ["Home"],
  endpoints: (builder) => ({
    getHome: builder.query({
      query: () => "/home",
      providesTags: ["Home"],
    }),
  }),
});

export const { useGetHomeQuery } = apiSlice;
